import React from 'react';
import './AboutSection.css';

function AboutSection() {
  return (
    <div className="web-section">
      <div className="background">
        <div className="ctent">
          <h1 className="display-4">US </h1>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
