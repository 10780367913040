import React from 'react';
import "./Privacy.css";

function Contact() {
  return (
    <div className="privacy">
      <div className="">
        <div className="private">
          <h1 className="">Sudocode Privacy Policy</h1>

        </div>
        <div>

          <p>

            At Sudocode, we prioritize your privacy and are dedicated to safeguarding your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you visit our website, www.sudocode.com (hereinafter referred to as "us," "we," or "our"). By using our website, you consent to the practices described in this Privacy Policy.</p>

          <h4>Information We Collect</h4>
          <p>Non-Personally Identifying Information
            Similar to many websites, Sudocode gathers non-personally identifying information typically made available by web browsers and servers. This information includes your browser type, language preferences, referring site, and the date and time of each visitor request. We use this data to gain insights into how our website is used by our visitors.</p>
          <h4>Personally Identifying Information</h4>
          <p>



            Sudocode may collect personally identifying information when you interact with us, such as when you subscribe to our newsletter or contact us directly. The information we collect depends on the nature of your interaction with us. For example, if you sign up for our newsletter, we will request your email address, which is used exclusively for communication purposes.</p>

          <h4>Security</h4><p>
            We place great importance on the security of your personal information. All data is transmitted over a secure, encrypted connection to ensure the protection of the information being transferred.
          </p>

          <h4>Advertisements</h4>
          <p>
            Sudocode does not display external advertisements on our website.</p>

          <h4>Links to External Sites</h4>
          <p>Our website may include links to external sites not operated by us. We strongly recommend reviewing the privacy policies and terms and conditions of any third-party websites you visit. Sudocode assumes no responsibility for the content, privacy policies, or practices of third-party sites, products, or services.
          </p>
          <h4>Sudocode's Use of Google AdWords for Remarketing</h4>
          <p>We use remarketing services, including Google AdWords, to advertise to previous visitors on third-party websites. This means that we may show advertisements to visitors who have not completed certain actions on our site, such as using our contact form to make an inquiry. These advertisements may appear on Google's search results page or within the Google Display Network. Any data collected is used in compliance with our own privacy policy and Google's privacy policy. You can manage your Google advertising preferences using the Google Ad Preferences page, and you have the option to opt out of interest-based advertising through your browser's cookie settings or by using a browser plugin.</p>

          <h4>Protection of Personally Identifying Information</h4><p>
            Sudocode only discloses potentially personally identifying and personally identifying information to employees who need the data to process it on our behalf or provide services available through our website. These individuals are required to keep such information confidential. Some of our employees, contractors, and affiliated organizations may be located outside your home country. By using our website, you consent to the transfer of your information to them. We will not rent or sell your personal information to anyone. Other than to our employees, contractors, and affiliated organizations as described above, we will only disclose such information in response to a subpoena, court order, or other governmental request, or when we believe in good faith that such disclosure is necessary to protect our rights, property, or the public at large. If you are a registered user of our website and have provided your email address, we may occasionally send you emails to inform you about new features, request your feedback, or keep you updated on Sudocode and our products. We primarily use our blog for this type of communication, so we aim to keep such emails to a minimum. If you contact us through a support email or feedback mechanism, we take reasonable measures to protect against unauthorized access, use, alteration, or destruction of your information.</p>

          <h4>Aggregated Statistics</h4><p>
            Sudocode may collect statistics regarding visitor behavior on our website. However, we do not disclose any personally identifying information.</p>

          <h4>Affiliate Disclosure</h4><p>
            We do not disclose any personal information for affiliate purposes.</p>

          <h4>Cookies</h4>
          <p>Sudocode does not use "Cookies" to collect any personally identifiable information.</p>

          <h4>E-commerce</h4><p>
            For users engaging in transactions with Sudocode, such as purchasing our services or products, we may collect additional information necessary to process these transactions. We collect such information only as needed to fulfill the purpose of your interaction with Sudocode. We do not disclose personally identifying information except as described below. Visitors may choose not to provide personally identifying information, although it may limit their ability to engage in certain website-related activities.</p>

          <h4>Business Transfers</h4><p>
            In the event that Sudocode experiences a significant business change, such as acquisition or bankruptcy, user information may be transferred to a third party. You acknowledge and consent to such potential transfers.</p>

          <h4>Changes to Our Privacy Policy</h4><p>
            While most changes are likely to be minor, Sudocode may update its Privacy Policy at its sole discretion. We encourage you to regularly check this page for any changes. Your continued use of this site after any modifications to this Privacy Policy will signify your acceptance of those changes.</p>

          <i>For any questions or concerns about our Privacy Policy, please feel free to contact us.</i>
        </div>
      </div>
    </div>
  );
}

export default Contact;
