import React from 'react';
import './PackageService.css';

function PackageService() {
  return (
    <div className="web-section">
      <div className="background-im">
        <div className="ctent">
          <h1 className="display-4">Our Packages </h1>

        </div>
      </div>
    </div>
  );
}

export default PackageService;
