import React from 'react';


function Ui() {
  return (
    <section className="serve-section">
      <div className="row">
        <div className="col-md-12">
          <section className="serve-section">
            <div className="offer-header">
              <div className="brand-header">
                <p className="rectangle-icon"></p>
                UI/UX & Design
              </div>


            </div>
            <div className="se-content">
              <h3>Elevate User Experiences with Sudocode's UI Design</h3>

              <p>

                At Sudocode, we understand that the user interface (UI) is the gateway to your digital world. It's where users connect with your brand, your message, and your products or services. That's why we're passionate about crafting UIs that are not just visually appealing but also highly functional and user-friendly.



                Our UI design philosophy centers around the user. We believe that every interaction should be intuitive, engaging, and purposeful. We invest time in understanding your target audience, their expectations, and their needs. This user-centric approach ensures that the UI we create resonates with your users.



                We recognize the power of aesthetics. Our UI designers have a keen eye for creating visually stunning interfaces. We believe that a beautiful UI not only captures attention but also leaves a lasting impression. Your users will not just navigate your website or app; they'll enjoy the journey.


                In today's multi-device world, responsiveness is essential. Our UIs are designed to adapt seamlessly to various screen sizes and devices. Whether your users are on a desktop, tablet, or smartphone, they'll have an optimal experience. Accessibility is also a priority, ensuring your content is available to everyone.



                Your brand identity is essential, and our UI design respects and enhances it. We maintain brand consistency throughout the UI, aligning colors, typography, and visual elements with your brand's character.



                UI design is not a one-time task. We believe in an iterative design process, constantly refining and enhancing the UI based on user feedback, analytics, and evolving design trends. Your UI will remain fresh and engaging over time.
              </p>

            </div>
          </section>
        </div>
      </div>


      <div className="col-md-12">
        <div className="serve-container1">
          <div className="col-md-6">
            <div className="serve-image2"></div>
          </div>
          <div className="col-md-6 serve1">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <g clip-path="url(#clip0_307_12168)">
                <path d="M51.5919 26.1224C51.5919 40.1888 40.1888 51.5918 26.1225 51.5918C12.0561 51.5918 0.653076 40.1888 0.653076 26.1224C0.653076 12.0561 12.0561 0.653023 26.1225 0.653023C40.1888 0.653023 51.5919 12.0561 51.5919 26.1224Z" stroke="#D100C9" stroke-width="2" />
                <path d="M63.3469 32C63.3469 46.0663 51.9439 57.4694 37.8776 57.4694C23.8112 57.4694 12.4082 46.0663 12.4082 32C12.4082 17.9336 23.8112 6.53059 37.8776 6.53059C51.9439 6.53059 63.3469 17.9336 63.3469 32Z" stroke="#D100C9" stroke-width="2" />
                <path d="M51.5919 37.8775C51.5919 51.9439 40.1888 63.3469 26.1225 63.3469C12.0561 63.3469 0.653076 51.9439 0.653076 37.8775C0.653076 23.8112 12.0561 12.4081 26.1225 12.4081C40.1888 12.4081 51.5919 23.8112 51.5919 37.8775Z" stroke="#D100C9" stroke-width="2" />
              </g>
              <defs>
                <clipPath id="clip0_307_12168">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h4 className="serve-title">UI/UX & Design</h4>
            <p className="serve-description">
              Our complete web design services will bring your ideas to life and provide you with a sleek, high-performing product that elevates your business. Our comprehensive UI/UX design services breathe life into your ideas and deliver a sleek, high-performing product that takes your business to new heights. We understand that an exceptional user interface and user experience are pivotal to a successful digital presence. Our team of seasoned UI/UX designers is dedicated to creating visually captivating, intuitive, and seamless user experiences.
            </p>
            <a href="/contact" className="serve-button">Start Project</a>
          </div>
        </div>



      </div>



    </section>
  );
}


export default Ui;
