import React from 'react';
import "./Privacy.css";

function Contact() {
  return (
    <div className="privacy">
      <div className="">
        <div className="private">
          <h1 className="">
            Sudocode Terms of Service</h1>

        </div>
        <div>

          <p>These Terms of Service ("ToS") outline the terms and conditions for using Sudocode's website at www.sudocode.com. By accessing and using this website, you agree to comply with and be bound by these terms. If you do not agree with these terms, please do not use our website.</p>

          <h4>Use of the Website</h4><p>You may be required to create a user account to access certain features of our website. You are responsible for maintaining the confidentiality of your account information, including your password. You agree to accept responsibility for all activities that occur under your account.
          </p>


          <h4>Acceptable Use</h4> <p>You agree to use the website for lawful and ethical purposes only. You will not engage in any activities that could harm, disrupt, or interfere with the proper functioning of the website.</p>

          <h4>Content</h4>
          <p>Sudocode may allow users to submit content, such as images or articles. By submitting content, you grant Sudocode a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and distribute your content.</p>

          <h4>Prohibited Content</h4> <p>You are prohibited from posting content that is unlawful, defamatory, offensive, or violates the rights of others. Sudocode reserves the right to remove any content that violates these guidelines.</p>

          <h4>Privacy</h4><p>
            Your use of the website is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using our website, you consent to the practices described in the Privacy Policy.</p>

          <h4>Intellectual Property & Copyright</h4>
          <p>All content on this website, including text, graphics, logos, and software, is the property of Sudocode and is protected by copyright laws. You may not reproduce, distribute, or create derivative works without our express permission.</p>

          <h4>Trademarks</h4> <p>The Sudocode name and logo are trademarks owned by Sudocode. You may not use our trademarks in any manner that could cause confusion or suggest endorsement without our permission.</p>

          <h4>Termination</h4>
          <p>Sudocode reserves the right to terminate or suspend your access to the website at our discretion. We may do so without notice, especially if you violate these ToS or engage in activities that could harm our website or its users.</p>

          <h4>Changes to Terms</h4>
          <p>We may revise these ToS from time to time. The most current version will always be available on our website. By continuing to use our website after any changes to the ToS, you agree to abide by the revised terms.</p>


          <i>If you have any questions or concerns about these Terms of Service, please contact us.</i>
        </div>
      </div>
    </div>
  );
}

export default Contact;
