import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import Package from '../components/packages/PackageService';
import PackageSection from '../components/packages/PackageSection';




function App() {
  return (
    <div className="App">
      <Package />
      <PackageSection />

    </div>
  );
}

export default App;
