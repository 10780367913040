import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import AboutSection from '../components/about/AboutSection';
import AboutUs from '../components/about/AboutUs';




function App() {
  return (
    <div className="App">
      <AboutSection />
      <AboutUs />

    </div>
  );
}

export default App;
