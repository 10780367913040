import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import ContactForm from '../components/contact/Form';
import Contact from '../components/contact/Contact';





function App() {
  return (
    <div className="App">
      <Contact />
      <ContactForm />
    </div>
  );
}

export default App;
