import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import ServiceSection from "../components/home/ServiceSection";
import SecondSection from '../components/home/SecondSection';
import WhoWeAreSection from '../components/home/WhoWeAre';
import BrandSection from '../components/home/BrandSection';



function App() {
  return (
    <div className="App">

      <SecondSection />
      <ServiceSection />
      <WhoWeAreSection />
      <BrandSection />
   
    </div>
  );
}

export default App;
