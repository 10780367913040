import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './PackageSection.css';

function PackageSection() {
  return (
    <div className='contain'>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <section className="serve-section">
              <div className="offer-header">
                <div className="brand-header">
                  <p className="rectangle-icon"></p>
                  What We Offer
                </div>
              </div>
              <div className="serve-content">
                <p className="serve-desc">
                  Take your business further with the best packages and deals on offer.
                </p>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_910)">
                    <g filter="url(#filter0_d_4_910)">
                      <g filter="url(#filter1_d_4_910)">
                        <path d="M122.678 58.233C123.98 63.0946 118.991 67.238 118.5 71.9626C118.002 76.7605 121.976 82.1344 119.866 86.3778C117.309 91.5223 110.338 91.8577 105.804 95.51C101.596 98.8991 99.9476 105.734 94.3933 107.223C88.839 108.711 83.9935 103.616 78.6551 102.784C72.9022 101.889 66.6976 105.084 61.9106 101.907C57.9622 99.2868 58.7169 92.6458 55.8864 88.7398C53.099 84.8936 46.7062 83.8001 45.4035 78.9385C44.1008 74.0769 49.0905 69.9335 49.5813 65.2089C50.0236 60.9508 45.8095 56.0799 47.5314 52.2401C49.8219 47.1322 54.4547 47.3164 58.6631 43.5489C63.1874 39.4987 67.4213 31.6278 73.6878 29.9487C79.9542 28.2696 87.5563 32.9691 93.4996 34.2145C99.0279 35.373 102.948 32.8971 107.486 36.1753C110.897 38.6398 109.683 44.9652 112.195 48.4317C114.982 52.2779 121.375 53.3714 122.678 58.233Z" fill="url(#paint0_linear_4_910)" />
                      </g>
                      <g filter="url(#filter2_dd_4_910)">
                        <path d="M111.364 77.8289C112.667 82.6905 107.677 86.8339 107.186 91.5585C106.688 96.3565 110.662 101.73 108.552 105.974C105.995 111.119 99.0242 111.453 94.4902 115.106C90.2824 118.495 88.6338 125.33 83.0796 126.819C77.5253 128.307 72.6798 123.211 67.3415 122.381C61.5884 121.484 55.384 124.68 50.5969 121.503C46.6484 118.883 47.4032 112.242 44.5726 108.336C41.7853 104.489 35.3924 103.396 34.0898 98.5344C32.7871 93.6728 37.7767 89.5294 38.2676 84.8048C38.7099 80.5467 34.4958 75.6758 36.2176 71.836C38.5082 66.7281 43.141 66.9123 47.3494 63.1448C51.8737 59.0946 56.1076 51.2237 62.3741 49.5446C68.6405 47.8655 76.2426 52.565 82.1859 53.8104C87.7142 54.9689 91.6343 52.493 96.172 55.7713C99.583 58.2358 98.3689 64.5611 100.881 68.0276C103.668 71.8738 110.061 72.9673 111.364 77.8289Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_910)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M86.2476 64.7629C87.6825 65.5914 88.1741 67.426 87.3457 68.8609L63.3041 110.502C62.4756 111.937 60.6409 112.429 59.206 111.6C57.7711 110.772 57.2795 108.937 58.1079 107.502L82.1496 65.861C82.978 64.4261 84.8127 63.9345 86.2476 64.7629Z" fill="white" fill-opacity="0.6" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M86.6473 93.7691C85.0469 94.198 84.0971 95.8429 84.526 97.4434C84.9548 99.0438 86.5998 99.9935 88.2002 99.5647C89.8007 99.1358 90.7504 97.4909 90.3215 95.8905C89.8927 94.29 88.2477 93.3403 86.6473 93.7691ZM78.7304 98.9963C77.4439 94.195 80.2932 89.2601 85.0944 87.9736C89.8956 86.6871 94.8306 89.5363 96.1171 94.3375C97.4036 99.1388 94.5544 104.074 89.7531 105.36C84.9519 106.647 80.0169 103.798 78.7304 98.9963Z" fill="white" fill-opacity="0.6" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M57.2534 76.7986C55.653 77.2274 54.7033 78.8724 55.1321 80.4728C55.5609 82.0733 57.2059 83.023 58.8063 82.5941C60.4068 82.1653 61.3565 80.5203 60.9277 78.9199C60.4988 77.3195 58.8539 76.3697 57.2534 76.7986ZM49.3365 82.0257C48.0501 77.2245 50.8993 72.2895 55.7005 71.003C60.5017 69.7165 65.4367 72.5658 66.7232 77.367C68.0097 82.1682 65.1605 87.1032 60.3592 88.3897C55.558 89.6762 50.623 86.827 49.3365 82.0257Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_910" x="29.8736" y="25.5904" width="105.02" height="113.499" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.627451 0 0 0 0 0.0705882 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_910" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_910" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_910" x="37.1873" y="21.5904" width="93.7064" height="93.903" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_910" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_910" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_910" x="25.8736" y="45.1864" width="93.7064" height="93.903" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_910" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_910" result="effect2_dropShadow_4_910" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_910" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_910" x="41.0276" y="60.3605" width="63.3984" height="63.6423" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_910" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_910" result="effect2_dropShadow_4_910" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_910" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_910" x1="73.6878" y1="29.9487" x2="94.3933" y2="107.223" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FFBC0E" />
                      <stop offset="1" stop-color="#FF7C1E" />
                    </linearGradient>
                    <clipPath id="clip0_4_910">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>Simple website — personal website</h2>
                <strong>R 2,500 - R6000</strong>
                <p>This is the cost for a simple personal website developed by using a content management system (CMS) Like Wordpress, Joomla, or Shopify. This includes only the initial creation of the website. </p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_883)">
                    <g filter="url(#filter0_d_4_883)">
                      <g filter="url(#filter1_d_4_883)">
                        <path d="M43.8866 68.75C43.0345 68.1008 42.845 66.8955 43.4572 66.0163L59.8858 42.4171C60.1556 42.0297 60.5536 41.7502 61.0096 41.628L90.507 33.7242C90.9629 33.602 91.4475 33.6451 91.8748 33.8457L117.903 46.0687C118.871 46.5243 119.31 47.6629 118.897 48.6511L95.3919 104.836C94.8866 106.043 93.3763 106.448 92.3349 105.655L43.8866 68.75Z" fill="url(#paint0_linear_4_883)" />
                      </g>
                      <g filter="url(#filter2_dd_4_883)">
                        <path d="M30.5024 80.6185C29.6502 79.9694 29.4608 78.764 30.0729 77.8848L46.5016 54.2857C46.7713 53.8982 47.1693 53.6187 47.6253 53.4965L77.1228 45.5927C77.5787 45.4705 78.0632 45.5136 78.4905 45.7142L104.518 57.9374C105.487 58.3927 105.926 59.5313 105.513 60.5196L82.0077 116.704C81.5024 117.912 79.9922 118.317 78.9507 117.524L30.5024 80.6185Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_883)">
                        <path d="M45.2217 76.2604C44.738 75.6813 44.6211 74.8794 44.9198 74.1864L54.2632 52.5048C54.5054 51.9429 54.9914 51.5228 55.5823 51.3644L60.4422 50.0622C61.5092 49.7764 62.6058 50.4095 62.8917 51.4765L67.6151 69.1046L78.6625 110.334C79.217 112.403 76.5696 113.778 75.1959 112.134L45.2217 76.2604Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter4_dd_4_883)">
                        <path d="M90.5863 64.105C90.7157 63.3617 90.4158 62.6087 89.8108 62.1579L73.4127 49.941C72.0724 48.9425 72.4755 46.8379 74.09 46.4053L77.1228 45.5927C77.5787 45.4705 78.0632 45.5136 78.4905 45.7142L104.518 57.9373C105.487 58.3927 105.926 59.5313 105.513 60.5195L87.8787 102.67C86.9587 104.87 83.6543 103.904 84.0633 101.555L90.5863 64.105Z" fill="white" fill-opacity="0.4" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_883" x="25.7142" y="29.656" width="105.338" height="100.277" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.647059 0 0 0 0 0.247059 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_883" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_883" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_883" x="35.0984" y="25.656" width="91.9536" height="88.4085" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_883" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_883" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_883" x="21.7142" y="41.5246" width="91.9539" height="88.4088" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_883" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_883" result="effect2_dropShadow_4_883" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_883" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_883" x="36.7565" y="45.9936" width="49.9813" height="78.8684" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_883" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_883" result="effect2_dropShadow_4_883" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_883" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_883" x="64.6066" y="41.5246" width="49.0614" height="74.3804" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_883" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_883" result="effect2_dropShadow_4_883" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_883" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_883" x1="47.0028" y1="81.9935" x2="122.82" y2="61.6783" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FFDF34" />
                      <stop offset="1" stop-color="#FFC83C" />
                    </linearGradient>
                    <clipPath id="clip0_4_883">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>Business Website</h2>

                <strong>R5,000 - R15,000</strong>
                <p>Business website costs hinge on size and features. Smaller ones contain 8-16 pages. Excluding maintenance, content, and marketing, costs typically span R5,000 to R15,000, averaging at R10,000.</p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_781)">
                    <g filter="url(#filter0_d_4_781)">
                      <g filter="url(#filter1_d_4_781)">
                        <path d="M52.5761 43.8877C51.4326 39.62 53.9652 35.2333 58.233 34.0898L104.597 21.6665C108.865 20.523 113.252 23.0556 114.395 27.3233L130.96 89.1426C132.103 93.4103 129.57 97.7971 125.303 98.9405L78.9385 111.364C74.6707 112.507 70.2841 109.975 69.1405 105.707L52.5761 43.8877Z" fill="url(#paint0_linear_4_781)" />
                      </g>
                      <g filter="url(#filter2_dd_4_781)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5076 54.8957C22.3077 53.8413 22.7672 51.875 24.3102 51.4616L41.8149 46.7712C43.1067 46.4251 44.4862 46.7474 45.4907 47.6303L65.7559 65.4399C59.1737 68.077 53.8618 72.7404 50.3604 78.4947L23.5076 54.8957ZM93.0488 67.0564L104.504 33.1927C105.016 31.6796 103.635 30.2066 102.092 30.62L84.5876 35.3103C83.2957 35.6565 82.2624 36.6253 81.8338 37.8922L73.1885 63.4483C80.2075 62.441 87.1392 63.8238 93.0488 67.0564Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_781)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M85.9774 126.042C103.048 121.468 113.179 103.921 108.605 86.8504C104.031 69.7795 86.4838 59.6488 69.4129 64.223C52.342 68.7971 42.2114 86.3439 46.7855 103.415C51.3597 120.486 68.9065 130.616 85.9774 126.042ZM83.9068 118.315C96.71 114.884 104.308 101.724 100.877 88.9209C97.4468 76.1178 84.2866 68.5198 71.4835 71.9504C58.6803 75.381 51.0823 88.5411 54.5129 101.344C57.9435 114.147 71.1037 121.745 83.9068 118.315Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_dd_4_781)">
                        <path d="M97.0137 89.9562C99.8725 100.626 93.5409 111.592 82.8715 114.451C72.2022 117.31 61.2355 110.978 58.3766 100.309C55.5178 89.6396 61.8494 78.6729 72.5188 75.8141C83.1881 72.9552 94.1548 79.2869 97.0137 89.9562Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_781" x="18.8267" y="17.3919" width="124.408" height="121.749" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.364706 0 0 0 0 0.827451 0 0 0 0.2 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_781" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_781" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_781" x="44.3015" y="13.3919" width="94.9328" height="106.247" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_781" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_781" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_781" x="14.8267" y="26.5494" width="97.7862" height="63.9453" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_781" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_781" result="effect2_dropShadow_4_781" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_781" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_781" x="37.6872" y="59.1247" width="80.0159" height="80.0159" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_781" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_781" result="effect2_dropShadow_4_781" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_781" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_781" x="49.6902" y="71.1276" width="56.0099" height="56.0099" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_781" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_781" result="effect2_dropShadow_4_781" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_781" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_781" x1="81.4152" y1="27.8781" x2="102.121" y2="105.152" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FC954B" />
                      <stop offset="1" stop-color="#F45DD3" />
                    </linearGradient>
                    <clipPath id="clip0_4_781">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>Custom Website</h2>

                <strong>R30,000 - R70,000</strong>
                <p>Tailored solutions, often exceeding 25 pages. Costs depend on page count, features, & business needs, covering initial setup but excluding annual maintenance, content creation, & marketing. Custom websites range from R30,000 to R70,000.</p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_920)">
                    <g filter="url(#filter0_d_4_920)">
                      <g filter="url(#filter1_d_4_920)">
                        <path d="M55.3926 95.9631C55.3618 94.1278 56.5839 92.5071 58.3568 92.032L118.007 76.049C119.779 75.574 121.648 76.3665 122.539 77.9714L130.543 92.3909C131.807 94.6658 130.595 97.5225 128.082 98.1959L60.7048 116.25C58.1912 116.923 55.7137 115.055 55.6701 112.453L55.3926 95.9631Z" fill="url(#paint0_linear_4_920)" />
                      </g>
                      <g filter="url(#filter2_d_4_920)">
                        <path d="M44.8487 45.9583C44.2769 43.8245 45.5433 41.6311 47.6771 41.0593L101.769 26.5654C103.903 25.9937 106.096 27.26 106.668 29.3939L117.021 68.0309C117.592 70.1647 116.326 72.3581 114.192 72.9299L60.1004 87.4237C57.9666 87.9955 55.7732 86.7291 55.2015 84.5953L44.8487 45.9583Z" fill="url(#paint1_linear_4_920)" />
                      </g>
                      <g filter="url(#filter3_dd_4_920)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6055 73.2816C31.3378 74.4252 28.8052 78.8118 29.9487 83.0796L41.3367 125.58C42.4802 129.848 46.8669 132.381 51.1347 131.237L112.954 114.673C117.222 113.529 119.754 109.142 118.611 104.875L107.223 62.3741C106.079 58.1063 101.693 55.5737 97.4248 56.7172L35.6055 73.2816ZM75.3134 78.5161C75.8235 80.42 77.0471 81.9446 78.6255 82.886L92.667 91.1209L100.574 77.2022C101.575 75.5538 101.929 73.5254 101.393 71.528C100.345 67.6159 96.2843 65.305 92.3234 66.3663C89.8977 67.0163 88.072 68.7802 87.2618 70.9484C85.4761 69.4758 83.013 68.861 80.5874 69.511C76.6264 70.5723 74.2651 74.6041 75.3134 78.5161Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_dd_4_920)">
                        <path d="M58.9265 75.315C59.9934 75.0291 61.0901 75.6623 61.3759 76.7292L62.4112 80.5929C62.6971 81.6599 62.064 82.7565 60.997 83.0424L41.6785 88.2188C40.6115 88.5047 39.5149 87.8715 39.229 86.8046L38.1937 82.9409C37.9078 81.8739 38.541 80.7773 39.6079 80.4914L58.9265 75.315Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter5_dd_4_920)">
                        <path d="M54.8225 90.9085C55.8895 90.6226 56.9861 91.2558 57.272 92.3227L58.3073 96.1864C58.5932 97.2534 57.96 98.35 56.8931 98.6359L45.302 101.742C44.235 102.028 43.1384 101.394 42.8525 100.328L41.8172 96.4638C41.5313 95.3969 42.1645 94.3002 43.2314 94.0143L54.8225 90.9085Z" fill="white" fill-opacity="0.4" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_920" x="25.6741" y="22.4282" width="117.375" height="121.084" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.54902 0 0 0 0 0.305882 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_920" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_920" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_920" x="47.3921" y="67.9123" width="91.6566" height="56.4756" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_920" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_920" result="shape" />
                    </filter>
                    <filter id="filter2_d_4_920" x="36.7114" y="18.4282" width="88.4466" height="77.1329" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_920" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_920" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_920" x="21.6741" y="52.4426" width="105.211" height="91.0691" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_920" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_920" result="effect2_dropShadow_4_920" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_920" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_920" x="30.1251" y="71.2464" width="40.3548" height="29.0411" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_920" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_920" result="effect2_dropShadow_4_920" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_920" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_920" x="33.7485" y="86.8399" width="32.6274" height="26.9705" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_920" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_920" result="effect2_dropShadow_4_920" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_920" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_920" x1="88.1816" y1="84.0405" x2="94.3933" y2="107.223" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FA9E49" />
                      <stop offset="1" stop-color="#FA5B5B" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_4_920" x1="74.723" y1="33.8124" x2="87.1464" y2="80.1768" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FA9E49" />
                      <stop offset="1" stop-color="#FA5B5B" />
                    </linearGradient>
                    <clipPath id="clip0_4_920">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>E-Commerce Website</h2>

                <strong>R10,000 - R20,000</strong>
                <p>E-commerce setup costs differ based on catalog size and customization. Basic platforms entail 100 hours; custom solutions are pricier. The average setup cost ranges from R10,000 to R20,000, typically settling around R15,000.</p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_932)">
                    <g filter="url(#filter0_d_4_932)">
                      <g filter="url(#filter1_d_4_932)">
                        <path d="M39.7095 57.6881C38.5659 53.4204 41.0986 49.0337 45.3663 47.8902L107.186 31.3257C111.453 30.1823 115.84 32.7148 116.984 36.9826L128.372 79.4833C129.515 83.7511 126.982 88.1378 122.715 89.2813L60.8955 105.846C56.6277 106.989 52.2411 104.457 51.0975 100.189L39.7095 57.6881Z" fill="url(#paint0_linear_4_932)" />
                      </g>
                      <g filter="url(#filter2_dd_4_932)">
                        <path d="M28.3958 77.284C27.2522 73.0163 29.7849 68.6296 34.0526 67.4861L95.8719 50.9217C100.14 49.7781 104.526 52.3108 105.67 56.5785L117.058 99.0792C118.201 103.347 115.669 107.734 111.401 108.877L49.5818 125.442C45.314 126.585 40.9273 124.053 39.7838 119.785L28.3958 77.284Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_932)">
                        <path d="M42.8525 100.328C42.2807 98.1937 43.5471 96.0003 45.6809 95.4286L55.3402 92.8404C57.474 92.2686 59.6674 93.535 60.2391 95.6688C60.8109 97.8026 59.5445 99.996 57.4107 100.568L47.7514 103.156C45.6176 103.728 43.4242 102.461 42.8525 100.328Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_dd_4_932)">
                        <path d="M39.229 86.8046C38.6572 84.6708 39.9236 82.4774 42.0574 81.9056L57.5122 77.7645C59.6461 77.1927 61.8395 78.4591 62.4112 80.5929C62.983 82.7268 61.7166 84.9202 59.5828 85.4919L44.128 89.633C41.9942 90.2048 39.8008 88.9384 39.229 86.8046Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter5_dd_4_932)">
                        <path d="M46.4759 113.851C45.9042 111.717 47.1705 109.523 49.3044 108.952L57.0318 106.881C59.1656 106.309 61.359 107.576 61.9307 109.709C62.5025 111.843 61.2361 114.037 59.1023 114.608L51.3749 116.679C49.2411 117.251 47.0477 115.984 46.4759 113.851Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter6_dd_4_932)">
                        <path d="M59.0973 56.6343C58.8115 55.5673 59.4446 54.4707 60.5116 54.1848L66.3071 52.6319C67.3741 52.346 68.4707 52.9791 68.7566 54.0461L70.8272 61.7735C71.113 62.8404 70.4799 63.9371 69.4129 64.223L63.6174 65.7759C62.5504 66.0618 61.4538 65.4286 61.1679 64.3617L59.0973 56.6343Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter7_dd_4_932)">
                        <path d="M74.1171 101.467C77.6245 95.8915 81.1064 92.148 87.8027 90.3537C94.499 88.5595 99.3862 90.0605 105.212 93.1357C107.799 94.5017 108.958 97.5421 108.142 100.22C107.848 101.185 106.95 101.788 105.976 102.049L77.9119 109.568C76.9378 109.829 75.8587 109.756 75.1218 109.068C73.0759 107.157 72.5591 103.944 74.1171 101.467Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter8_dd_4_932)">
                        <path d="M86.7674 86.49C91.5687 85.2035 94.4179 80.2686 93.1314 75.4673C91.8449 70.6661 86.9099 67.8169 82.1087 69.1034C77.3074 70.3898 74.4582 75.3248 75.7447 80.1261C77.0312 84.9273 81.9662 87.7765 86.7674 86.49Z" fill="white" fill-opacity="0.4" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_932" x="24.1212" y="27.0512" width="116.525" height="110.665" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.180392 0 0 0 0 0.764706 0 0 0 0 0.835294 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_932" x="31.4349" y="23.0512" width="105.211" height="91.0691" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_932" x="20.1212" y="46.6471" width="105.211" height="91.0691" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_932" x="34.7152" y="88.7031" width="33.6612" height="26.5902" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_932" x="31.0917" y="73.6272" width="39.4568" height="28.1431" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_932" x="38.3387" y="102.744" width="31.7294" height="26.0725" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter6_dd_4_932" x="51.0287" y="48.5632" width="27.8671" height="29.2813" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter7_dd_4_932" x="65.2084" y="85.5819" width="51.1914" height="36.1257" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <filter id="filter8_dd_4_932" x="67.4358" y="64.7945" width="34.0045" height="34.0045" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_932" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_932" result="effect2_dropShadow_4_932" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_932" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_932" x1="76.276" y1="39.6079" x2="91.8051" y2="97.5635" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#31C8D2" />
                      <stop offset="1" stop-color="#1AA6E1" />
                    </linearGradient>
                    <clipPath id="clip0_4_932">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>Mobile App Development</h2>

                <strong>R140,000 - R600,000</strong>
                <p>Mobile app development costs vary with project complexity. Simple apps may take 200 hours, while complex ones exceed 500 hours. Expenses typically range from R140,000 to R600,000, depending on features, platform & compatibility.


                </p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="148" viewBox="0 0 157 148" fill="none">
                  <g filter="url(#filter0_d_4_976)">
                    <g filter="url(#filter1_d_4_976)">
                      <path d="M123.713 62.0966C129.431 83.4353 116.767 105.369 95.4286 111.086C74.0899 116.804 52.1564 104.141 46.4388 82.8022C40.7211 61.4635 53.3844 39.53 74.723 33.8124C96.0617 28.0947 117.995 40.758 123.713 62.0966Z" fill="url(#paint0_linear_4_976)" />
                    </g>
                    <g filter="url(#filter2_dd_4_976)">
                      <path d="M47.8701 130.632C46.2989 131.574 44.3933 130.084 44.9289 128.331L65.2338 61.9404C65.666 60.5271 67.4291 60.0547 68.5102 61.0625L119.29 108.406C120.63 109.656 119.725 111.9 117.893 111.87L93.9945 111.475C93.4078 111.465 92.8465 111.714 92.4592 112.154L84.1318 121.63C83.5988 122.236 82.7534 122.463 81.9886 122.204L70.0392 118.162C69.4834 117.974 68.873 118.039 68.3699 118.341L47.8701 130.632Z" fill="white" fill-opacity="0.6" />
                    </g>
                    <g filter="url(#filter3_dd_4_976)">
                      <path d="M92.4507 110.25L74.7681 75.6871C73.6754 73.5513 70.4349 74.7983 71.0558 77.1157L82.2845 119.022C82.6978 120.564 84.6641 121.024 85.7186 119.824L92.1725 112.481C92.714 111.865 92.8244 110.98 92.4507 110.25Z" fill="white" fill-opacity="0.6" />
                    </g>
                    <g filter="url(#filter4_dd_4_976)">
                      <path d="M62.8372 69.7767L44.9289 128.331C44.3933 130.084 46.2989 131.574 47.8701 130.632L68.1333 118.483C68.7671 118.103 69.1397 117.404 69.1023 116.666L66.7472 70.2602C66.6337 68.0238 63.4921 67.6353 62.8372 69.7767Z" fill="white" fill-opacity="0.6" />
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_976" x="40.8367" y="28.4395" width="96.249" height="114.485" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.180392 0 0 0 0 0.764706 0 0 0 0 0.835294 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_976" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_976" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_976" x="37.0659" y="24.4395" width="96.0199" height="96.0198" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_976" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_976" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_976" x="36.8367" y="56.5243" width="91.0939" height="86.3999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_976" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_976" result="effect2_dropShadow_4_976" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_976" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_976" x="62.9774" y="70.5878" width="37.6927" height="61.9173" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_976" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_976" result="effect2_dropShadow_4_976" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_976" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_976" x="36.8367" y="64.3606" width="40.2681" height="78.5636" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_976" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_976" result="effect2_dropShadow_4_976" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_976" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_976" x1="74.723" y1="33.8124" x2="95.4286" y2="111.086" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#31C8D2" />
                      <stop offset="1" stop-color="#1AA6E1" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>UI/UX & Design</h2>

                <strong>Custom</strong>
                <p>UI/UX design expenses adjust to project intricacy. Basic designs require 100 hours, intricate ones demand 200 hours. Typically, UI/UX design costs range from R10,000 to R200,000, factoring in user flow, visuals, interactivity, and ongoing enhancements. </p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_893)">
                    <g filter="url(#filter0_d_4_893)">
                      <path d="M93.5983 88.8008C91.0253 79.1985 96.7238 69.3284 106.326 66.7554C115.928 64.1825 125.799 69.881 128.372 79.4833C130.945 89.0857 125.246 98.9558 115.644 101.529C106.041 104.102 96.1712 98.4032 93.5983 88.8008Z" fill="url(#paint0_linear_4_893)" />
                    </g>
                    <g filter="url(#filter1_d_4_893)">
                      <path d="M47.4741 86.6659C46.9023 84.5321 48.1687 82.3387 50.3025 81.7669L77.3484 74.52C79.4822 73.9482 81.6756 75.2146 82.2474 77.3484L89.4943 104.394C90.0661 106.528 88.7997 108.722 86.6659 109.293L59.62 116.54C57.4861 117.112 55.2927 115.846 54.721 113.712L47.4741 86.6659Z" fill="url(#paint1_linear_4_893)" />
                    </g>
                    <g filter="url(#filter2_d_4_893)">
                      <path d="M59.3646 33.7866L45.5102 37.4989C44.9978 37.6361 44.561 37.9713 44.2958 38.4307L38.0531 49.2433C37.7879 49.7027 37.716 50.2486 37.8533 50.7609L41.5656 64.6154C41.7029 65.1277 42.0381 65.5645 42.4975 65.8297L53.31 72.0724C53.7694 72.3376 54.3153 72.4095 54.8276 72.2722L68.6821 68.5599C69.1944 68.4226 69.6313 68.0874 69.8965 67.628L76.1391 56.8155C76.4044 56.3561 76.4762 55.8102 76.3389 55.2979L72.6266 41.4434C72.4894 40.9311 72.1542 40.4942 71.6948 40.229L60.8822 33.9864C60.4228 33.7211 59.8769 33.6493 59.3646 33.7866Z" fill="url(#paint2_linear_4_893)" />
                    </g>
                    <g filter="url(#filter3_d_4_893)">
                      <path d="M94.5371 26.2141C94.7024 25.4067 95.7183 25.1345 96.265 25.7511L120.568 53.1588C121.062 53.7148 120.797 54.5957 120.079 54.788L88.4318 63.268C87.7137 63.4604 87.0442 62.8299 87.1933 62.1016L94.5371 26.2141Z" fill="url(#paint3_linear_4_893)" />
                    </g>
                    <g filter="url(#filter4_dd_4_893)">
                      <path d="M82.2845 108.397C79.7116 98.7944 85.4101 88.9243 95.0125 86.3513C104.615 83.7784 114.485 89.4769 117.058 99.0792C119.631 108.682 113.932 118.552 104.33 121.125C94.7276 123.698 84.8575 117.999 82.2845 108.397Z" fill="white" fill-opacity="0.4" />
                    </g>
                    <g filter="url(#filter5_dd_4_893)">
                      <path d="M36.1603 106.262C35.5886 104.128 36.8549 101.935 38.9888 101.363L66.0347 94.1159C68.1685 93.5441 70.3619 94.8105 70.9337 96.9443L78.1806 123.99C78.7523 126.124 77.486 128.317 75.3522 128.889L48.3062 136.136C46.1724 136.708 43.979 135.441 43.4073 133.308L36.1603 106.262Z" fill="white" fill-opacity="0.4" />
                    </g>
                    <g filter="url(#filter6_dd_4_893)">
                      <path d="M48.0509 53.3825L34.1965 57.0948C33.6841 57.2321 33.2473 57.5672 32.9821 58.0266L26.7394 68.8392C26.4742 69.2986 26.4023 69.8445 26.5396 70.3568L30.2519 84.2113C30.3892 84.7236 30.7244 85.1604 31.1838 85.4257L41.9963 91.6683C42.4557 91.9335 43.0016 92.0054 43.5139 91.8681L57.3684 88.1558C57.8807 88.0185 58.3176 87.6833 58.5828 87.2239L64.8254 76.4114C65.0907 75.952 65.1625 75.4061 65.0252 74.8938L61.313 61.0393C61.1757 60.527 60.8405 60.0902 60.3811 59.8249L49.5686 53.5823C49.1092 53.3171 48.5633 53.2452 48.0509 53.3825Z" fill="white" fill-opacity="0.4" />
                    </g>
                    <g filter="url(#filter7_dd_4_893)">
                      <path d="M83.2234 45.81C83.3887 45.0027 84.4046 44.7304 84.9514 45.347L109.255 72.7546C109.748 73.3108 109.484 74.1916 108.766 74.384L77.1181 82.8639C76.4001 83.0563 75.7306 82.4258 75.8796 81.6975L83.2234 45.81Z" fill="white" fill-opacity="0.4" />
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_893" x="84.9805" y="58.1376" width="52.0089" height="52.0089" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_893" x="39.3368" y="66.3827" width="58.2948" height="58.2948" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter2_d_4_893" x="29.7852" y="25.7184" width="54.6219" height="54.6219" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter3_d_4_893" x="79.1723" y="17.4138" width="49.6487" height="53.8892" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_893" x="73.6667" y="81.7335" width="52.0089" height="52.0089" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_893" result="effect2_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_893" x="28.023" y="89.9786" width="58.2948" height="58.2948" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_893" result="effect2_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter6_dd_4_893" x="18.4715" y="49.3143" width="54.6219" height="54.6219" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_893" result="effect2_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_893" result="shape" />
                    </filter>
                    <filter id="filter7_dd_4_893" x="67.8586" y="41.0097" width="49.6488" height="53.8892" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_893" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_893" result="effect2_dropShadow_4_893" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_893" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_893" x1="106.326" y1="66.7554" x2="115.644" y2="101.529" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6966FF" />
                      <stop offset="1" stop-color="#9D68F3" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_4_893" x1="63.8254" y1="78.1434" x2="73.1429" y2="112.917" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6966FF" />
                      <stop offset="1" stop-color="#9D68F3" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_4_893" x1="52.4374" y1="35.6427" x2="61.7549" y2="70.416" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6966FF" />
                      <stop offset="1" stop-color="#9D68F3" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_4_893" x1="95.258" y1="25.4487" x2="104.256" y2="59.028" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6966FF" />
                      <stop offset="1" stop-color="#9D68F3" />
                    </linearGradient>
                    <clipPath id="clip0_4_893">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>AI Integrations & Big Data</h2>

                <strong>Custom</strong>
                <p>AI integrations drive business growth. Costs adjust to customization, data needs, and complexity. Basic AI can demand R40,000, complex solutions more. Invest wisely for efficiency, data insights, &  tailored experiences.</p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 custom-col">
            <div className="custom-content">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_748)">
                    <g filter="url(#filter0_d_4_748)">
                      <g filter="url(#filter1_d_4_748)">
                        <path d="M122.678 58.233C128.395 79.5716 115.732 101.505 94.3933 107.223C73.0546 112.94 51.1212 100.277 45.4035 78.9385C39.6858 57.5998 52.3491 35.6664 73.6878 29.9487C95.0264 24.231 116.96 36.8943 122.678 58.233Z" fill="url(#paint0_linear_4_748)" />
                      </g>
                      <g filter="url(#filter2_dd_4_748)">
                        <path d="M40.8191 123.648C40.5332 122.582 41.1663 121.485 42.2333 121.199L84.734 109.811C85.801 109.525 86.8976 110.158 87.1835 111.225L89.2541 118.953C89.5401 120.02 88.9068 121.116 87.8399 121.402L45.3391 132.79C44.2722 133.076 43.1756 132.443 42.8896 131.376L40.8191 123.648Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_748)">
                        <path d="M37.1956 110.126C36.9097 109.059 37.5429 107.962 38.6098 107.676L81.1106 96.288C82.1775 96.0021 83.2742 96.6352 83.56 97.7022L85.6306 105.43C85.9165 106.497 85.2833 107.593 84.2164 107.879L41.7157 119.267C40.6487 119.553 39.5521 118.92 39.2662 117.853L37.1956 110.126Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_dd_4_748)">
                        <path d="M33.5721 96.6026C33.2863 95.5356 33.9194 94.439 34.9864 94.1531L77.4871 82.765C78.5541 82.4791 79.6507 83.1123 79.9366 84.1792L82.0071 91.9066C82.293 92.9736 81.6599 94.0702 80.5929 94.3561L38.0922 105.744C37.0252 106.03 35.9286 105.397 35.6427 104.33L33.5721 96.6026Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter5_dd_4_748)">
                        <path d="M29.9487 83.0796C29.6628 82.0126 30.2959 80.916 31.3629 80.6301L73.8636 69.2421C74.9306 68.9562 76.0272 69.5893 76.3131 70.6563L78.3837 78.3837C78.6696 79.4506 78.0364 80.5473 76.9695 80.8332L34.4687 92.2212C33.4018 92.5071 32.3051 91.874 32.0192 90.807L29.9487 83.0796Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter6_dd_4_748)">
                        <path d="M26.3252 69.5566C26.0393 68.4897 26.6725 67.393 27.7394 67.1071L70.2402 55.7191C71.3071 55.4332 72.4038 56.0663 72.6897 57.1333L74.7602 64.8607C75.0461 65.9277 74.413 67.0243 73.346 67.3102L30.8453 78.6982C29.7783 78.9841 28.6817 78.351 28.3958 77.284L26.3252 69.5566Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter7_dd_4_748)">
                        <g filter="url(#filter8_dd_4_748)">
                          <path d="M80.4914 117.159C80.2054 116.092 80.8386 114.996 81.9056 114.71L116.679 105.392C117.746 105.107 118.842 105.739 119.128 106.807L121.199 114.534C121.485 115.601 120.852 116.698 119.785 116.984L85.0114 126.301C83.9445 126.587 82.8479 125.954 82.5619 124.887L80.4914 117.159Z" fill="white" fill-opacity="0.6" />
                        </g>
                        <g filter="url(#filter9_dd_4_748)">
                          <path d="M76.3503 101.705C76.0644 100.638 76.6975 99.541 77.7645 99.2551L112.538 89.9376C113.605 89.6517 114.701 90.2849 114.987 91.3518L117.058 99.0792C117.344 100.146 116.711 101.243 115.644 101.529L80.8703 110.846C79.8034 111.132 78.7067 110.499 78.4208 109.432L76.3503 101.705Z" fill="white" fill-opacity="0.6" />
                        </g>
                        <g filter="url(#filter10_dd_4_748)">
                          <path d="M72.2092 86.2498C71.9233 85.1828 72.5564 84.0862 73.6234 83.8003L108.397 74.4828C109.464 74.1969 110.56 74.8301 110.846 75.897L112.917 83.6244C113.203 84.6914 112.57 85.788 111.503 86.0739L76.7292 95.3914C75.6623 95.6773 74.5656 95.0442 74.2797 93.9772L72.2092 86.2498Z" fill="white" fill-opacity="0.6" />
                        </g>
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_748" x="22.2566" y="24.5758" width="113.794" height="120.283" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.615686 0 0 0 0 0.407843 0 0 0 0 0.952941 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_748" x="36.0306" y="20.5758" width="96.0199" height="96.0199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_748" x="32.7504" y="105.742" width="64.5723" height="39.1165" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_748" x="29.127" y="92.2193" width="64.5723" height="39.1164" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_748" x="25.5035" y="78.6964" width="64.5723" height="39.1164" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_748" x="21.88" y="65.1734" width="64.5723" height="39.1164" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter6_dd_4_748" x="18.2566" y="51.6505" width="64.5723" height="39.1164" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter7_dd_4_748" x="64.1405" y="70.4142" width="65.1271" height="67.9555" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter8_dd_4_748" x="72.4227" y="101.324" width="56.845" height="37.0459" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter9_dd_4_748" x="68.2816" y="85.869" width="56.8449" height="37.0459" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <filter id="filter10_dd_4_748" x="64.1405" y="70.4142" width="56.8449" height="37.0459" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_748" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_748" result="effect2_dropShadow_4_748" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_748" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_748" x1="73.6878" y1="29.9487" x2="94.3933" y2="107.223" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6966FF" />
                      <stop offset="1" stop-color="#9D68F3" />
                    </linearGradient>
                    <clipPath id="clip0_4_748">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="content-heder">

                <h2>Testing & Security</h2>

                <strong>R25,000 - R150,000</strong>
                <p>Testing & security are essential, costs depend on complexity & scope. Audits start at R25,000, while comprehensive evaluations exceed R100,000. Security mitigates risks, avoiding damage from breaches.</p>
                <a href="/contact" className="btn-get">Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageSection;
