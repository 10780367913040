import React from 'react';
import './WhoWeAre.css'; // Import your CSS file

function WhoWeAreSection() {
  return (
    <section className="who-we-are-section">
      <div className="who-header">
        <div className="brand-header">
          <p className="rectangle-icon"></p>
          Who We Are
        </div>
      </div>
      <div className="who-container">
        <div className="image" style={{ backgroundImage: 'url("images/6.webp")' }}>
          <img src="" alt="" />
        </div>
        <div className="description">
          <p>
            SudoCode’s team strives to deliver high-performing and future-proof software solutions that give your business the edge it needs for market expansion and growth.
          </p>
          <p>
            We believe that technology is key to business and project success, and that is why we apply exhaustive efforts to establish the full picture of our clients' needs before embarking on the journey of developing the final solution.
          </p>
          <p>
            We always aim for a plan-hard, fail-sooner approach, which reduces the amount of frustration and re-work that can occur during major projects, saving our clients time, money, and energy while building a trusted and successful business relationship.
          </p>
          <a href="/about" className="service-button">Learn More About Us</a>
        </div>
      </div>
    </section>
  );
}

export default WhoWeAreSection;
