import React from 'react';
import Button from 'react-bootstrap/Button';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="company">

          <h3>Company</h3>

          <div className="company-links">
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/packages">Packages</a>
            <a href="/services">Services</a>
            <a href="/contact">Contact Us</a>
          </div>
        </div>
        <div className="address">
          <h3>Address</h3>
          <p>
            First Floor, Willowbridge Centre,<br />
            Carl Cronje Dr<br />
            Cape Town<br />
            7530
          </p>
        </div>

        <div className="follow-us">
          <h3>Follow Us</h3>
          <div className="social-icons">

            <a href="https://www.facebook.com/profile.php?id=61551070753498" aria-label="Facebook">
              <span className="sr-only">Facebook</span>
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </a>
            <a href="https://www.instagram.com/your-instagram-account" aria-label="Instagram">
              <span className="sr-only">Instagram</span>
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </a>


            <a href="https://twitter.com/your-twitter-account" aria-label="Twitter">
              <span className="sr-only">Twitter</span>
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </a>
            <a href="https://www.linkedin.com/in/your-linkedin-profile" aria-label="Linkedin">
              <span className="sr-only">Linkedin</span>
              <FontAwesomeIcon icon={['fab', 'linkedin']} />
            </a>


          </div>
        </div>
        <div className="get-in-touch">
          <h3>Get in Touch</h3>
          <p>sudo@sudocodes.co.za</p>
          <p>+27785359097</p>
          <div className="contact-buttons">
            <div className="contact-buttons">
              <a href="mailto:sudo@sudocodes.co.za">
                <Button>Email Us</Button>
              </a>
              <a href="tel:+27785359097">
                <Button>Phone Us</Button>
              </a>
              <a href="https://wa.me/27785359097">
                <Button>WhatsApp Us</Button>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div className="additional-link">
        <a href="#">Careers | </a>
        <a href="/terms"> Our Terms | </a>
        <a href="/privacy"> Privacy Policy | </a>
        <a href="/impressum"> Impressum</a>

      </div>
      <p className='last-p'>&copy; 2000 - 2023 SudoCode</p>
    </footer>
  );
}

export default Footer;
