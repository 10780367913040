import React from 'react';
import './Webdev.css'; // Import the CSS file

function Webdev() {
  return (
    <section className="serve-section">
      <div className="row">
        <div className="col-md-12">
          <section className="serve-section">
            <div className="offer-header">
              <div className="brand-header">
                <p className="rectangle-icon"></p>
                Website Development
              </div>


            </div>
            <div className="se-content">
              <h3>From Concept to Reality: Elevate Your Web Presence with Sudocode</h3>

              <p>

                At Sudocode, we specialize in crafting stunning web applications tailored to your company's needs and new product aspirations. Our dedicated web development team thrives on turning your ideas into digital reality.
                We believe that every web application we create be meticulously designed to meet your exact specifications and requirements. We don't just build websites; we build experiences. Throughout the development process, we provide expert guidance to ensure a seamless journey.

                With a rich portfolio of diverse projects, we've accumulated a wealth of experience. From sleek product landing pages to intricate e-commerce platforms, we've done it all. Your project is in capable hands.

                We love transforming innovative concepts into tangible, digital solutions. To kick off the process, we'll schedule a briefing session where you can share your vision with us. Whether it's a simple idea or a complex web application, we're here to make it happen.

                Your web application should reflect your unique brand identity. Our team can source high-quality images from stock providers or, if you prefer, our talented graphic designers can craft custom graphics that resonate with your website's style and purpose.
              </p>

            </div>
          </section>
        </div>
      </div>


      <div className="col-md-12">
        <div className="serve-container">
          <div className="col-md-6">
            <div className="serve-image1"></div>
          </div>
          <div className="col-md-6 serve">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <g clip-path="url(#clip0_307_12163)">
                <path d="M52.898 55.5103H11.102C5.33123 55.5103 0.653061 50.8321 0.653061 45.0613V18.9388C0.653061 13.168 5.33123 8.48985 11.102 8.48985H52.898C58.6688 8.48985 63.3469 13.168 63.3469 18.9388V45.0613C63.3469 50.8321 58.6688 55.5103 52.898 55.5103Z" stroke="#D100C9" stroke-width="2" />
                <path d="M42.449 47.6735H21.551C18.6656 47.6735 16.3265 45.3344 16.3265 42.449V21.551C16.3265 18.6656 18.6656 16.3265 21.551 16.3265H42.449C45.3344 16.3265 47.6735 18.6656 47.6735 21.551V42.449C47.6735 45.3344 45.3344 47.6735 42.449 47.6735Z" stroke="#D100C9" stroke-width="2" />
                <path d="M45.0612 63.3469H18.9388C13.168 63.3469 8.48979 58.6688 8.48979 52.8979V11.102C8.48979 5.33121 13.168 0.653046 18.9388 0.653046H45.0612C50.832 0.653046 55.5102 5.33121 55.5102 11.102V52.8979C55.5102 58.6688 50.832 63.3469 45.0612 63.3469Z" stroke="#D100C9" stroke-width="2" />
              </g>
              <defs>
                <clipPath id="clip0_307_12163">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h4 id='serve1' className="serve-title">Website Development</h4>
            <p className="serve-description">
              We use cutting-edge web development technologies to help our clients fulfill their business goals through reliable solutions. We leverage web development technologies to empower our clients to achieve their business objectives through robust and dependable solutions. Web development is at the core of our expertise, and we take pride in crafting visually engaging, user-friendly websites and web applications. Our dedicated team of developers, designers, and digital strategists collaborates seamlessly to create dynamic online experiences tailored to your specific needs. Whether you require a corporate website, e-commerce platform, or custom web application, we're committed to delivering outstanding success.
            </p>
            <a href="/contact" className="serve-button">Start Project</a>
          </div>
        </div>
      </div>



    </section>
  );
}


export default Webdev;
