import React from "react";
import "./Form.css";


const ContactForm = () => {
  return (

    <div className="form-map-container">
      <div className="map-container">
        <div className="responsive-iframe">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13253.601972464856!2d18.629823707509832!3d-33.85356814183571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc50b068af7aa5%3A0x866b38c4ed816be9!2sSudoCode%20-%20Cape%20Town%2C%20Tyger%20Valley%2C%20Willowbridge!5e0!3m2!1sen!2sza!4v1697608815695!5m2!1sen!2sza"
            width="100%"
            height="540"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="form-container">
        <form className="form" method="POST" action="https://formspree.io/f/mqkjgawz">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="form-control"
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="form-control"
              placeholder="Your Company Email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="number"
              id="phone"
              name="phone"
              required
              className="form-control"
              placeholder="Your Phone Number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="textarea">How Can We Help You?</label>
            <textarea
              name="textarea"
              id="textarea"
              rows="5"
              required
              className="form-control"
              placeholder="Your message here"
            />
          </div>
          <button type="submit" className="form-submit-btn">
            Submit
          </button>
        </form>

      </div>
    </div>
  );
};

export default ContactForm;
