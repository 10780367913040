import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import './Navbar.css';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'; // Import Link

class Navigation extends Component {
  render() {
    return (
      <Navbar className="navbar-style" variant="dark" expand="lg">
        <Navbar.Brand className='logo-style' href="/home">
          <Nav.Link href="/" className="buton" data-text="Awesome">
            <span className="actual-text">&nbsp;SudoCode&nbsp;</span>
            <span aria-hidden="true" className="hover-text">&nbsp;SudoCode&nbsp;</span>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="/" className="nav-link">Home</Nav.Link>
            <Link to="/services" className="nav-link">Services</Link>
            <Link to="/packages" className="nav-link">Packages</Link>
            <Nav.Link href="/about" className="nav-link">About Us</Nav.Link>
            <Nav.Link href="/contact" className="nav-link">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Button href="/contact" className='button-style' variant="light">Let's Talk</Button>
      </Navbar >
    );
  }
}

export default Navigation;
