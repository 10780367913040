import React from 'react';
import './BrandSection.css';

const brandIcons = [
  { id: 1, src: '/images/bbc-black.svg', alt: 'BBC Black', },
  { id: 2, src: '/images/distell-black.svg', alt: 'Distell Black', },
  { id: 3, src: '/images/engen-black.svg', alt: 'Engen Black', },
  { id: 4, src: '/images/liquid-black.svg', alt: 'Liquid Black', },
  { id: 5, src: '/images/microsoft-black.svg', alt: 'Microsoft Black', },
  { id: 6, src: '/images/multichoice-black.svg', alt: 'Multichoice Black', },
  { id: 7, src: '/images/nike-black.svg', alt: 'Nike Black', },
  { id: 8, src: '/images/pnp-black.svg', alt: 'PNP Black', },
  { id: 9, src: '/images/sanlam-black.svg', alt: 'Sanlam Black', },
  { id: 10, src: '/images/santam-black.svg', alt: 'santam Black', },
  { id: 11, src: '/images/spotify-black.svg', alt: 'Spotify Black', },
  { id: 12, src: '/images/tfg-black.svg', alt: 'tfg Black', },
  { id: 13, src: '/images/tyme-bank-black.svg', alt: 'tyme Black', }, {
    id: 14, src: '/images/visa-black.svg', alt: 'visa Black',
  },
  { id: 15, src: '/images/wesgrow-black.svg', alt: 'wesgrow Black', },
];

function BrandSection() {
  return (
    <section className="brand-container">
      <div className="brand-header">
        <p className='rectangle-icon'></p>
        You will be in good company
      </div>
      <h2>Trusted by Leading Brands</h2>
      <div className="brand-cards">
        {brandIcons.map((brand, index) => {
          return (
            <div key={index} className="brand-card">
              <img
                src={brand.src}
                alt={brand.alt}
                className="brand-icon"
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default BrandSection;
