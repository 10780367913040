import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <section className="serve-section">
            <div className="offer-header">
              <div className="brand-header">
                <p className="rectangle-icon"></p>
                Who We Are
              </div>


            </div>
            <div className="se-content">

              <p>Established in 2016, SudoCode is a software company headquartered in Cape Town, South Africa, with a notable track record spanning over half a decade. Our expertise encompasses a wide spectrum of IT services, including software development, mobile app development, network management, and cybersecurity solutions.

                At SudoCode, we pride ourselves on our commitment to delivering results-driven software solutions that are intricately tailored to meet the unique needs of your business. Whether you require top-notch JavaScript, Ruby on Rails, .NET, Java, or PHP development, we have the expertise and experience to cover your project from inception to completion.

                Our comprehensive approach ensures that all your technology requirements are met under one roof. Whether you are seeking dedicated support services, a bespoke mobile app, or full-stack software development, we have the skills and knowledge to deliver outstanding results.

                We believe that the foundation of our success lies in fostering enduring relationships with our clients. As your business flourishes, so does ours, and we stand by your side every step of the way. Continuous consultation is a cornerstone of our client-centric approach, ensuring that your business strategies seamlessly integrate with the software solutions we provide. In today's dynamic business landscape, especially for those making the shift to cloud infrastructure, our guidance becomes indispensable.
              </p>
            </div>
          </section>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <section className="serve-section">
            <div className="offer-header">
              <div className="brand-header">
                <p className="rectangle-icon"></p>
                Why Chose Us?
              </div>

            </div>
            <div className="si-content">

              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_823)">
                    <g filter="url(#filter0_d_4_823)">
                      <g filter="url(#filter1_d_4_823)">
                        <path d="M46.7805 45.4407C45.637 41.1729 48.1696 36.7863 52.4374 35.6427L94.9381 24.2547C99.2059 23.1111 103.593 25.6438 104.736 29.9115L121.301 91.7308C122.444 95.9985 119.911 100.385 115.644 101.529L73.1429 112.917C68.8752 114.06 64.4885 111.528 63.345 107.26L46.7805 45.4407Z" fill="url(#paint0_linear_4_823)" />
                      </g>
                      <g filter="url(#filter2_dd_4_823)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.1237 55.2386C36.8559 56.3822 34.3233 60.7688 35.4668 65.0366L52.0312 126.856C53.1747 131.123 57.5615 133.656 61.8292 132.513L104.33 121.125C108.598 119.981 111.13 115.594 109.987 111.327L93.4224 49.5075C92.2788 45.2397 87.8922 42.7071 83.6244 43.8506L41.1237 55.2386ZM49.5074 63.345C47.3736 63.9167 46.1072 66.1101 46.679 68.244C47.2508 70.3778 49.4441 71.6441 51.578 71.0724L82.4876 62.7902C84.6214 62.2184 85.8878 60.025 85.316 57.8912C84.7443 55.7574 82.5509 54.491 80.417 55.0628L49.5074 63.345Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_823)">
                        <path d="M64.7592 104.81C65.3309 106.944 64.0646 109.138 61.9307 109.709C59.7969 110.281 57.6035 109.015 57.0318 106.881C56.46 104.747 57.7264 102.554 59.8602 101.982C61.994 101.41 64.1874 102.677 64.7592 104.81Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter4_dd_4_823)">
                        <path d="M67.865 116.402C68.4367 118.535 67.1704 120.729 65.0366 121.301C62.9027 121.872 60.7093 120.606 60.1376 118.472C59.5658 116.338 60.8322 114.145 62.966 113.573C65.0998 113.001 67.2932 114.268 67.865 116.402Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter5_dd_4_823)">
                        <path d="M61.6533 93.2193C62.2251 95.3532 60.9587 97.5466 58.8249 98.1183C56.6911 98.6901 54.4977 97.4237 53.9259 95.2899C53.3542 93.1561 54.6205 90.9627 56.7544 90.3909C58.8882 89.8191 61.0816 91.0855 61.6533 93.2193Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter6_dd_4_823)">
                        <path d="M58.5475 81.6282C59.1193 83.762 57.8529 85.9554 55.7191 86.5272C53.5853 87.099 51.3919 85.8326 50.8201 83.6988C50.2484 81.5649 51.5147 79.3715 53.6485 78.7998C55.7824 78.228 57.9758 79.4944 58.5475 81.6282Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter7_dd_4_823)">
                        <path d="M80.214 100.669C80.7857 102.803 79.5194 104.997 77.3855 105.568C75.2517 106.14 73.0583 104.874 72.4866 102.74C71.9148 100.606 73.1812 98.4127 75.315 97.8409C77.4488 97.2692 79.6422 98.5355 80.214 100.669Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter8_dd_4_823)">
                        <path d="M83.3198 112.26C83.8915 114.394 82.6252 116.588 80.4914 117.159C78.3576 117.731 76.1641 116.465 75.5924 114.331C75.0206 112.197 76.287 110.004 78.4208 109.432C80.5547 108.86 82.7481 110.127 83.3198 112.26Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter9_dd_4_823)">
                        <path d="M77.1082 89.0782C77.6799 91.212 76.4136 93.4054 74.2797 93.9772C72.1459 94.549 69.9525 93.2826 69.3808 91.1488C68.809 89.0149 70.0754 86.8215 72.2092 86.2498C74.343 85.678 76.5364 86.9444 77.1082 89.0782Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter10_dd_4_823)">
                        <path d="M74.0023 77.4871C74.5741 79.6209 73.3077 81.8143 71.1739 82.3861C69.0401 82.9578 66.8467 81.6915 66.2749 79.5577C65.7032 77.4238 66.9695 75.2304 69.1033 74.6587C71.2372 74.0869 73.4306 75.3533 74.0023 77.4871Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter11_dd_4_823)">
                        <path d="M95.6688 96.5282C96.2406 98.6621 94.9742 100.855 92.8404 101.427C90.7065 101.999 88.5131 100.733 87.9414 98.5988C87.3696 96.465 88.636 94.2716 90.7698 93.6998C92.9036 93.128 95.097 94.3944 95.6688 96.5282Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter12_dd_4_823)">
                        <path d="M98.7746 108.119C99.3464 110.253 98.08 112.447 95.9462 113.018C93.8124 113.59 91.619 112.324 91.0472 110.19C90.4755 108.056 91.7418 105.863 93.8757 105.291C96.0095 104.719 98.2029 105.985 98.7746 108.119Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter13_dd_4_823)">
                        <path d="M92.563 84.9371C93.1347 87.0709 91.8684 89.2643 89.7345 89.8361C87.6007 90.4079 85.4073 89.1415 84.8356 87.0077C84.2638 84.8738 85.5302 82.6804 87.664 82.1087C89.7978 81.5369 91.9912 82.8033 92.563 84.9371Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter14_dd_4_823)">
                        <path d="M89.4571 73.346C90.0289 75.4798 88.7625 77.6732 86.6287 78.245C84.4949 78.8167 82.3015 77.5504 81.7297 75.4166C81.158 73.2827 82.4243 71.0893 84.5582 70.5176C86.692 69.9458 88.8854 71.2122 89.4571 73.346Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_823" x="31.1922" y="19.9801" width="102.383" height="124.807" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.192157 0 0 0 0 0.823529 0 0 0 0 0.556863 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_823" x="38.506" y="15.9801" width="91.0691" height="105.211" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_823" x="27.1922" y="39.576" width="91.0691" height="105.211" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_823" x="48.8945" y="97.8447" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_823" x="52.0003" y="109.436" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_823" x="45.7887" y="86.2536" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter6_dd_4_823" x="42.6828" y="74.6625" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter7_dd_4_823" x="64.3493" y="93.7036" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter8_dd_4_823" x="67.4551" y="105.295" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter9_dd_4_823" x="61.2435" y="82.1125" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter10_dd_4_823" x="58.1376" y="70.5214" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter11_dd_4_823" x="79.8041" y="89.5625" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter12_dd_4_823" x="82.9099" y="101.154" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter13_dd_4_823" x="76.6983" y="77.9714" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <filter id="filter14_dd_4_823" x="73.5925" y="66.3803" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_823" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_823" result="effect2_dropShadow_4_823" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_823" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_823" x1="73.6878" y1="29.9487" x2="94.3933" y2="107.223" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#21EB66" />
                      <stop offset="1" stop-color="#12D583" />
                    </linearGradient>
                    <clipPath id="clip0_4_823">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className='si'>
                <h4>Cost Efficiency</h4>
                <p>
                  Partnering with SudoCode converts fixed IT costs into variable costs, allowing you to budget effectively. You pay for the services you use when you need them, optimizing your IT expenditure.</p>
              </div>

            </div>
            <div className="si-content">

              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_775)">
                    <g filter="url(#filter0_d_4_775)">
                      <g filter="url(#filter1_d_4_775)">
                        <path d="M122.678 58.233C128.395 79.5716 115.732 101.505 94.3933 107.223C73.0546 112.94 51.1212 100.277 45.4035 78.9385C39.6858 57.5998 52.3491 35.6664 73.6878 29.9487C95.0264 24.231 116.96 36.8943 122.678 58.233Z" fill="url(#paint0_linear_4_775)" />
                      </g>
                      <g filter="url(#filter2_dd_4_775)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.0796 126.819C104.418 121.101 117.082 99.1675 111.364 77.8289C105.646 56.4902 83.7127 43.8269 62.3741 49.5446C41.0354 55.2623 28.3721 77.1957 34.0898 98.5344C39.8075 119.873 61.7409 132.536 83.0796 126.819ZM69.3436 60.1004C68.7718 57.9666 66.5784 56.7003 64.4446 57.272C62.3108 57.8438 61.0444 60.0372 61.6162 62.171L62.2712 64.6155C52.0585 69.1315 45.8947 79.8074 47.0901 90.9099L44.6456 91.5649C42.5118 92.1366 41.2454 94.33 41.8172 96.4638C42.3889 98.5977 44.5823 99.864 46.7162 99.2923L49.1606 98.6373C53.6767 108.85 64.3525 115.014 75.4551 113.818L76.11 116.263C76.6818 118.397 78.8752 119.663 81.009 119.091C83.1429 118.52 84.4092 116.326 83.8375 114.192L83.1825 111.748C93.3952 107.232 99.5589 96.5559 98.3635 85.4534L100.808 84.7984C102.942 84.2267 104.208 82.0333 103.636 79.8994C103.065 77.7656 100.871 76.4992 98.7375 77.071L96.293 77.726C91.777 67.5133 81.1011 61.3495 69.9986 62.5449L69.3436 60.1004ZM84.7852 80.8095L88.4272 79.8336C85.2499 73.8454 78.8808 70.1682 72.1062 70.4107L73.0821 74.0527C73.6539 76.1866 72.3875 78.3799 70.2536 78.9516C68.1198 79.5234 65.9264 78.2571 65.3547 76.1232L64.3788 72.4813C58.3906 75.6585 54.7134 82.0277 54.9559 88.8022L58.5978 87.8264C60.7318 87.2546 62.9251 88.521 63.4968 90.6548C64.0686 92.7886 62.8023 94.982 60.6684 95.5538L57.0265 96.5296C60.2036 102.518 66.5729 106.195 73.3474 105.953L72.3715 102.311C71.7998 100.177 73.0661 97.9833 75.2 97.4115C77.3338 96.8398 79.5272 98.1061 80.0989 100.24L81.0748 103.882C87.063 100.705 90.7403 94.3356 90.4977 87.561L86.8558 88.5369C84.7219 89.1087 82.5286 87.8423 81.9568 85.7085C81.3851 83.5746 82.6513 81.3813 84.7852 80.8095Z" fill="white" fill-opacity="0.4" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_775" x="28.7169" y="24.5758" width="107.334" height="115.616" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.615686 0 0 0 0 0.407843 0 0 0 0 0.952941 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_775" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_775" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_775" x="36.0306" y="20.5758" width="96.0199" height="96.0198" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_775" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_775" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_775" x="24.7169" y="44.1717" width="96.0199" height="96.0198" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_775" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_775" result="effect2_dropShadow_4_775" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_775" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_775" x1="73.6878" y1="29.9487" x2="94.3933" y2="107.223" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#6966FF" />
                      <stop offset="1" stop-color="#9D68F3" />
                    </linearGradient>
                    <clipPath id="clip0_4_775">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className='si'>
                <h4>Quality Assurance</h4>
                <p>
                  With over five years of experience and a top-notch development team, SudoCode guarantees high-quality software products. Our proprietary processes ensure efficient and performance-driven solutions.</p>
              </div>

            </div>

            <div className="si-content">

              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_853)">
                    <g filter="url(#filter0_d_4_853)">
                      <g filter="url(#filter1_d_4_853)">
                        <path d="M39.1918 55.7563C38.0483 51.4885 40.5809 47.1018 44.8487 45.9583L106.668 29.3939C110.935 28.2504 115.322 30.783 116.466 35.0507L127.854 77.5515C128.997 81.8192 126.465 86.206 122.197 87.3494L60.3778 103.914C56.1101 105.057 51.7234 102.525 50.5799 98.257L39.1918 55.7563Z" fill="url(#paint0_linear_4_853)" />
                      </g>
                      <g filter="url(#filter2_dd_4_853)">
                        <path d="M27.8781 75.3522L28.9134 79.2159L106.187 58.5104L105.152 54.6467C104.009 50.3789 99.622 47.8463 95.3542 48.9898L33.535 65.5542C29.2672 66.6978 26.7346 71.0844 27.8781 75.3522Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_853)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M107.223 62.3741L117.576 101.011C118.719 105.279 116.186 109.666 111.919 110.809L50.0994 127.373C45.8317 128.517 41.4449 125.984 40.3014 121.717L29.9487 83.0796L107.223 62.3741ZM95.909 81.97C98.0429 81.3982 99.3092 79.2048 98.7375 77.071C98.1657 74.9372 95.9723 73.6708 93.8385 74.2426C91.7047 74.8143 90.4383 77.0077 91.0101 79.1416C91.5818 81.2754 93.7752 82.5417 95.909 81.97Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_dd_4_853)">
                        <path d="M58.5847 112.677C59.1564 114.81 57.8901 117.004 55.7563 117.576C53.6224 118.147 51.429 116.881 50.8573 114.747C50.2855 112.613 51.5519 110.42 53.6857 109.848C55.8195 109.276 58.0129 110.543 58.5847 112.677Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter5_dd_4_853)">
                        <path d="M55.4789 101.085C56.0506 103.219 54.7843 105.413 52.6504 105.984C50.5166 106.556 48.3232 105.29 47.7514 103.156C47.1797 101.022 48.446 98.8288 50.5799 98.257C52.7137 97.6852 54.9071 98.9516 55.4789 101.085Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter6_dd_4_853)">
                        <path d="M52.373 89.4943C52.9448 91.6281 51.6784 93.8215 49.5446 94.3933C47.4108 94.9651 45.2174 93.6987 44.6456 91.5649C44.0739 89.431 45.3402 87.2376 47.474 86.6659C49.6079 86.0941 51.8013 87.3605 52.373 89.4943Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter7_dd_4_853)">
                        <path d="M74.0395 108.535C74.6112 110.669 73.3449 112.863 71.2111 113.434C69.0772 114.006 66.8838 112.74 66.3121 110.606C65.7403 108.472 67.0067 106.279 69.1405 105.707C71.2743 105.135 73.4677 106.402 74.0395 108.535Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter8_dd_4_853)">
                        <path d="M70.9337 96.9443C71.5054 99.0781 70.2391 101.272 68.1052 101.843C65.9714 102.415 63.778 101.149 63.2063 99.0149C62.6345 96.881 63.9009 94.6876 66.0347 94.1159C68.1685 93.5441 70.3619 94.8105 70.9337 96.9443Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter9_dd_4_853)">
                        <path d="M67.8278 85.3532C68.3996 87.487 67.1332 89.6804 64.9994 90.2522C62.8656 90.8239 60.6722 89.5576 60.1004 87.4238C59.5287 85.2899 60.795 83.0965 62.9289 82.5248C65.0627 81.953 67.2561 83.2194 67.8278 85.3532Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter10_dd_4_853)">
                        <path d="M89.4943 104.394C90.0661 106.528 88.7997 108.722 86.6659 109.293C84.5321 109.865 82.3387 108.599 81.7669 106.465C81.1951 104.331 82.4615 102.138 84.5953 101.566C86.7292 100.994 88.9226 102.26 89.4943 104.394Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter11_dd_4_853)">
                        <path d="M86.3885 92.8032C86.9602 94.937 85.6939 97.1304 83.56 97.7022C81.4262 98.274 79.2328 97.0076 78.6611 94.8738C78.0893 92.7399 79.3557 90.5465 81.4895 89.9748C83.6233 89.403 85.8167 90.6694 86.3885 92.8032Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter12_dd_4_853)">
                        <path d="M83.2827 81.2121C83.8544 83.3459 82.5881 85.5393 80.4542 86.1111C78.3204 86.6828 76.127 85.4165 75.5552 83.2827C74.9835 81.1488 76.2499 78.9554 78.3837 78.3837C80.5175 77.8119 82.7109 79.0783 83.2827 81.2121Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter13_dd_4_853)">
                        <path d="M104.949 100.253C105.521 102.387 104.255 104.58 102.121 105.152C99.9869 105.724 97.7935 104.458 97.2217 102.324C96.65 100.19 97.9163 97.9965 100.05 97.4248C102.184 96.853 104.377 98.1194 104.949 100.253Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter14_dd_4_853)">
                        <path d="M101.843 88.6621C102.415 90.7959 101.149 92.9893 99.0149 93.5611C96.881 94.1328 94.6876 92.8665 94.1159 90.7327C93.5441 88.5988 94.8105 86.4054 96.9443 85.8337C99.0781 85.2619 101.272 86.5283 101.843 88.6621Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_853" x="23.6035" y="25.1193" width="116.525" height="114.529" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.627451 0 0 0 0 0.0705882 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_853" x="30.9173" y="21.1193" width="105.211" height="91.0691" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_853" x="19.6035" y="44.7152" width="94.5839" height="46.5007" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_853" x="21.9487" y="58.3741" width="103.901" height="81.274" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_853" x="42.72" y="105.711" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_853" x="39.6142" y="94.1197" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter6_dd_4_853" x="36.5083" y="82.5286" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter7_dd_4_853" x="58.1748" y="101.57" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter8_dd_4_853" x="55.069" y="89.9786" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter9_dd_4_853" x="51.9632" y="78.3875" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter10_dd_4_853" x="73.6296" y="97.4286" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter11_dd_4_853" x="70.5238" y="85.8375" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter12_dd_4_853" x="67.418" y="74.2464" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter13_dd_4_853" x="89.0844" y="93.2875" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <filter id="filter14_dd_4_853" x="85.9786" y="81.6964" width="24.002" height="24.002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_853" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_853" result="effect2_dropShadow_4_853" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_853" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_853" x1="75.7583" y1="37.6761" x2="91.2875" y2="95.6316" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#FFBC0E" />
                      <stop offset="1" stop-color="#FF7C1E" />
                    </linearGradient>
                    <clipPath id="clip0_4_853">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className='si'>
                <h4>Focus on Core Business</h4>
                <p>
                  Outsourcing IT services to SudoCode allows your business to concentrate on its core functions. You can avoid getting sidetracked by complex software development decisions, enabling you to stay focused on your primary objectives.</p>
              </div>

            </div>

            <div className="si-content">

              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_811)">
                    <g filter="url(#filter0_d_4_811)">
                      <g filter="url(#filter1_d_4_811)">
                        <path d="M36.6036 46.097C36.0319 43.9632 37.2982 41.7698 39.4321 41.198L108.979 22.5631C111.112 21.9913 113.306 23.2577 113.878 25.3915L128.372 79.4833C128.943 81.6172 127.677 83.8106 125.543 84.3823L55.9965 103.017C53.8627 103.589 51.6693 102.323 51.0975 100.189L36.6036 46.097Z" fill="url(#paint0_linear_4_811)" />
                      </g>
                      <g filter="url(#filter2_dd_4_811)">
                        <path d="M26.5528 70.4059C25.8995 67.9677 27.6401 65.5414 30.1591 65.3789L64.3613 63.1731C65.3127 63.1117 66.1751 63.7305 66.4219 64.6513L81.9304 122.53C82.257 123.749 81.3869 124.963 80.1275 125.044L45.1267 127.301C43.2239 127.424 41.4993 126.187 41.0056 124.345L26.5528 70.4059Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_811)">
                        <path d="M66.4219 64.6513C66.1751 63.7305 66.6127 62.7634 67.4673 62.3409L98.1902 47.15C100.453 46.0312 103.174 47.2622 103.827 49.7003L118.28 103.639C118.773 105.481 117.898 107.415 116.189 108.26L84.7489 123.806C83.6175 124.365 82.257 123.749 81.9304 122.53L66.4219 64.6513Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_dd_4_811)">
                        <path d="M32.729 62.5463C32.3676 61.1975 33.4646 59.903 34.8545 60.0382L63.2386 62.7992C64.8981 62.9607 66.2834 64.1345 66.715 65.7451L81.068 119.311C81.7909 122.009 79.5968 124.598 76.817 124.328L49.926 121.712C49.0962 121.631 48.4035 121.044 48.1878 120.239L32.729 62.5463Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter5_dd_4_811)">
                        <path d="M66.715 65.7451C66.2834 64.1345 66.8962 62.4253 68.2527 61.4557L91.4535 44.8725C92.5895 44.0605 94.1869 44.6331 94.5483 45.9819L110.007 103.675C110.223 104.48 109.916 105.335 109.238 105.82L87.2577 121.53C84.9857 123.154 81.7909 122.009 81.068 119.311L66.715 65.7451Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_811" x="22.4146" y="18.4258" width="118.094" height="120.884" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.823529 0 0 0 0 0.192157 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_811" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_811" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_811" x="28.4664" y="14.4258" width="108.042" height="96.7288" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_811" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_811" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_811" x="18.4146" y="59.169" width="71.5849" height="80.1404" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_811" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_811" result="effect2_dropShadow_4_811" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_811" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_811" x="58.3535" y="42.7336" width="68.063" height="93.2802" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_811" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_811" result="effect2_dropShadow_4_811" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_811" result="shape" />
                    </filter>
                    <filter id="filter4_dd_4_811" x="24.6594" y="56.0284" width="64.5477" height="80.3188" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_811" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_811" result="effect2_dropShadow_4_811" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_811" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_811" x="58.5784" y="40.4983" width="59.4968" height="93.7804" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_811" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_811" result="effect2_dropShadow_4_811" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_811" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_811" x1="74.2054" y1="31.8805" x2="90.7698" y2="93.6998" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#93DF32" />
                      <stop offset="1" stop-color="#70BD0D" />
                    </linearGradient>
                    <clipPath id="clip0_4_811">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className='si'>
                <h4>Quick Project Initiation</h4>
                <p>
                  SudoCode has the resources and expertise to start your project promptly. In-house projects often involve time-consuming hiring and training processes, but we can kickstart your initiatives without delays.</p>
              </div>

            </div>

            <div className="si-content">

              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="157" height="157" viewBox="0 0 157 157" fill="none">
                  <g clip-path="url(#clip0_4_958)">
                    <g filter="url(#filter0_d_4_958)">
                      <g filter="url(#filter1_d_4_958)">
                        <path d="M37.1213 48.0289C35.9778 43.7611 38.5104 39.3744 42.7782 38.2309C47.0459 37.0874 51.4326 39.62 52.5761 43.8878L73.2816 121.162C74.4251 125.429 71.8925 129.816 67.6248 130.96C63.357 132.103 58.9703 129.57 57.8268 125.303L37.1213 48.0289Z" fill="url(#paint0_linear_4_958)" />
                      </g>
                      <g filter="url(#filter2_d_4_958)">
                        <path d="M83.4857 35.6055C82.3422 31.3378 84.8748 26.9511 89.1426 25.8076C93.4103 24.6641 97.797 27.1967 98.9406 31.4644L119.646 108.739C120.79 113.006 118.257 117.393 113.989 118.536C109.721 119.68 105.335 117.147 104.191 112.88L83.4857 35.6055Z" fill="url(#paint1_linear_4_958)" />
                      </g>
                      <g filter="url(#filter3_dd_4_958)">
                        <path d="M66.5152 64.9994C68.8023 73.5349 63.737 82.3083 55.2015 84.5953C46.666 86.8824 37.8926 81.8171 35.6056 73.2816C33.3185 64.7461 38.3838 55.9728 46.9193 53.6857C55.4548 51.3986 64.2281 56.4639 66.5152 64.9994Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter4_d_4_958)">
                        <path d="M62.6515 66.0347C64.3667 72.4363 60.5677 79.0163 54.1662 80.7316C47.7646 82.4469 41.1845 78.6479 39.4692 72.2464C37.7539 65.8448 41.5529 59.2647 47.9545 57.5494C54.3561 55.8341 60.9362 59.6331 62.6515 66.0347Z" fill="url(#paint2_linear_4_958)" />
                      </g>
                      <g filter="url(#filter5_dd_4_958)">
                        <path d="M121.162 83.4857C123.449 92.0212 118.384 100.795 109.848 103.082C101.313 105.369 92.5393 100.303 90.2522 91.768C87.9651 83.2325 93.0304 74.4591 101.566 72.172C110.101 69.885 118.875 74.9502 121.162 83.4857Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter6_d_4_958)">
                        <path d="M117.298 84.521C119.013 90.9226 115.214 97.5026 108.813 99.2179C102.411 100.933 95.8312 97.1342 94.1159 90.7327C92.4006 84.3311 96.1996 77.751 102.601 76.0357C109.003 74.3204 115.583 78.1194 117.298 84.521Z" fill="url(#paint3_linear_4_958)" />
                      </g>
                      <g filter="url(#filter7_dd_4_958)">
                        <path d="M57.8218 67.3288C58.8224 71.0631 56.6064 74.9014 52.8721 75.902C49.1378 76.9026 45.2995 74.6865 44.2989 70.9523C43.2983 67.218 45.5144 63.3796 49.2486 62.379C52.9829 61.3785 56.8213 63.5945 57.8218 67.3288Z" fill="white" fill-opacity="0.6" />
                      </g>
                      <g filter="url(#filter8_dd_4_958)">
                        <path d="M112.468 85.8151C113.469 89.5494 111.253 93.3877 107.519 94.3883C103.784 95.3889 99.9461 93.1728 98.9455 89.4386C97.9449 85.7043 100.161 81.866 103.895 80.8654C107.63 79.8648 111.468 82.0808 112.468 85.8151Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_958" x="31.0564" y="21.533" width="102.655" height="121.701" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.823529 0 0 0 0 0.192157 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_958" x="28.8467" y="29.9563" width="52.7095" height="109.278" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter2_d_4_958" x="75.2112" y="17.533" width="52.7095" height="109.278" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_958" x="27.0564" y="49.1366" width="48.0079" height="48.0079" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_958" result="effect2_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter4_d_4_958" x="31.0574" y="49.1375" width="40.006" height="40.006" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter5_dd_4_958" x="81.703" y="67.6229" width="48.008" height="48.0079" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_958" result="effect2_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter6_d_4_958" x="85.704" y="67.6239" width="40.0059" height="40.006" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter7_dd_4_958" x="36.0586" y="58.1388" width="30.0035" height="30.0035" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_958" result="effect2_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_958" result="shape" />
                    </filter>
                    <filter id="filter8_dd_4_958" x="90.7053" y="76.6251" width="30.0035" height="30.0035" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_958" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_958" result="effect2_dropShadow_4_958" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_958" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_958" x1="42.7782" y1="38.2309" x2="67.6248" y2="130.96" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#93DF32" />
                      <stop offset="1" stop-color="#70BD0D" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_4_958" x1="89.1426" y1="25.8076" x2="113.989" y2="118.536" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#93DF32" />
                      <stop offset="1" stop-color="#70BD0D" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_4_958" x1="47.9545" y1="57.5494" x2="54.1662" y2="80.7316" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#93DF32" />
                      <stop offset="1" stop-color="#70BD0D" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_4_958" x1="102.601" y1="76.0357" x2="108.813" y2="99.2179" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#93DF32" />
                      <stop offset="1" stop-color="#70BD0D" />
                    </linearGradient>
                    <clipPath id="clip0_4_958">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className='si'>
                <h4>Reduced Labor Costs</h4>
                <p>
                  Hiring and training quality software developers can be expensive. Outsourcing allows you to allocate your human resources where they are most needed, saving on labor costs.</p>
              </div>

            </div>

            <div className="si-content">

              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="145" height="157" viewBox="0 0 145 157" fill="none">
                  <g clip-path="url(#clip0_4_950)">
                    <g filter="url(#filter0_d_4_950)">
                      <g filter="url(#filter1_d_4_950)">
                        <path d="M46.7806 45.4407C45.637 41.1729 48.1697 36.7862 52.4374 35.6427L98.8019 23.2194C103.069 22.0759 107.456 24.6085 108.6 28.8762L125.164 90.6955C126.308 94.9632 123.775 99.35 119.507 100.493L73.143 112.917C68.8752 114.06 64.4885 111.528 63.345 107.26L46.7806 45.4407Z" fill="url(#paint0_linear_4_950)" />
                      </g>
                      <g filter="url(#filter2_dd_4_950)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.4717 79.4561C39.2039 80.5997 36.6713 84.9863 37.8148 89.2541L48.1676 127.891C49.311 132.159 53.6978 134.692 57.9655 133.548L100.466 122.16C104.734 121.016 107.267 116.629 106.123 112.362L95.7704 73.7249C94.6268 69.4572 90.2402 66.9245 85.9724 68.0681L43.4717 79.4561ZM78.6611 94.8738C79.4275 97.734 78.5427 100.648 76.5909 102.603L77.9032 107.5C78.475 109.634 77.2086 111.827 75.0748 112.399C72.941 112.971 70.7476 111.705 70.1758 109.571L68.8635 104.673C66.1958 103.956 63.9727 101.875 63.2063 99.0149C62.0627 94.7471 64.5954 90.3605 68.8631 89.2169C73.1309 88.0734 77.5176 90.606 78.6611 94.8738Z" fill="white" fill-opacity="0.4" />
                      </g>
                      <g filter="url(#filter3_dd_4_950)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4494 71.6916L80.1768 69.621C77.8897 61.0855 69.1164 56.0202 60.5809 58.3073C52.0454 60.5944 46.9801 69.3677 49.2672 77.9032L56.9946 75.8327C55.8511 71.5649 58.3837 67.1782 62.6515 66.0347C66.9192 64.8912 71.3059 67.4238 72.4494 71.6916Z" fill="white" fill-opacity="0.6" />
                      </g>
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_4_950" x="33.5402" y="18.9448" width="103.899" height="126.878" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="4" dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.192157 0 0 0 0 0.823529 0 0 0 0 0.556863 0 0 0 0.4 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_950" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_950" result="shape" />
                    </filter>
                    <filter id="filter1_d_4_950" x="38.506" y="14.9448" width="94.9328" height="106.247" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_950" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_950" result="shape" />
                    </filter>
                    <filter id="filter2_dd_4_950" x="29.5402" y="63.7935" width="84.8575" height="82.029" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_950" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_950" result="effect2_dropShadow_4_950" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_950" result="shape" />
                    </filter>
                    <filter id="filter3_dd_4_950" x="40.718" y="53.7581" width="47.4588" height="36.1451" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_950" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="4" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4_950" result="effect2_dropShadow_4_950" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4_950" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_950" x1="75.6196" y1="29.431" x2="96.3252" y2="106.705" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#21EB66" />
                      <stop offset="1" stop-color="#12D583" />
                    </linearGradient>
                    <clipPath id="clip0_4_950">
                      <rect width="128" height="128" fill="white" transform="translate(0 33.1288) rotate(-15)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>

              <div className='si'>
                <h4>Compliance and Security</h4>
                <p>
                  SudoCode ensures that your software and IT infrastructure meet industry standards for compliance and security. We handle sensitive data, maintain security measures, and help your business adhere to necessary standards, such as PCI compliance.</p>
              </div>

            </div>

          </section>

        </div>
        <div className="col-md-6">
          {/* You can add more content here */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
