import React from 'react';
import './SecondSection.css';

function SecondSection() {
  return (
    <div className="second-section">
      <div className="background-image">
        <div className="content">

          <h1 className="display-1">Software Development </h1>
          <h1 className="display-2">Solutions For Your Business</h1>
          <p className="lead">Empowering your business with high-performance, software solutions that provide </p>
          <p>the competitive edge necessary for market expansion and sustained growth.</p>
          <a href="/contact" className="btn btn-purple">Start your project</a>

        </div>
      </div>
    </div>
  );
}

export default SecondSection;
