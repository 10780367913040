import React from 'react';
import './WebSection.css'; // Import the CSS file

function WebSection() {
  return (
    <section className="serve-section">
      <div className="row">
        <div className="col-md-12">
          <section className="serve-section">
            <div className="offer-header">
              <div className="brand-header">
                <p className="rectangle-icon"></p>
                What we do
              </div>
            </div>
            <div className="serve-content">
              <p className="serve-desc">
                We offer a complete range of bespoke design and development services to help you turn your ideas into digital masterpieces.
              </p>
            </div>
          </section>
        </div>
      </div>

      <div className="col-md-12">
        <div className="serve-container">
          <div className="col-md-6">
            <div className="serve-image1"></div>
          </div>
          <div className="col-md-6 serve">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <g clip-path="url(#clip0_307_12163)">
                <path d="M52.898 55.5103H11.102C5.33123 55.5103 0.653061 50.8321 0.653061 45.0613V18.9388C0.653061 13.168 5.33123 8.48985 11.102 8.48985H52.898C58.6688 8.48985 63.3469 13.168 63.3469 18.9388V45.0613C63.3469 50.8321 58.6688 55.5103 52.898 55.5103Z" stroke="#D100C9" stroke-width="2" />
                <path d="M42.449 47.6735H21.551C18.6656 47.6735 16.3265 45.3344 16.3265 42.449V21.551C16.3265 18.6656 18.6656 16.3265 21.551 16.3265H42.449C45.3344 16.3265 47.6735 18.6656 47.6735 21.551V42.449C47.6735 45.3344 45.3344 47.6735 42.449 47.6735Z" stroke="#D100C9" stroke-width="2" />
                <path d="M45.0612 63.3469H18.9388C13.168 63.3469 8.48979 58.6688 8.48979 52.8979V11.102C8.48979 5.33121 13.168 0.653046 18.9388 0.653046H45.0612C50.832 0.653046 55.5102 5.33121 55.5102 11.102V52.8979C55.5102 58.6688 50.832 63.3469 45.0612 63.3469Z" stroke="#D100C9" stroke-width="2" />
              </g>
              <defs>
                <clipPath id="clip0_307_12163">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h4 id='serve1' className="serve-title">Website Development</h4>
            <p className="serve-description">
              We use cutting-edge web development technologies to help our clients fulfill their business goals through reliable solutions. We leverage web development technologies to empower our clients to achieve their business objectives through robust and dependable solutions. Web development is at the core of our expertise, and we take pride in crafting visually engaging, user-friendly websites and web applications. Our dedicated team of developers, designers, and digital strategists collaborates seamlessly to create dynamic online experiences tailored to your specific needs. Whether you require a corporate website, e-commerce platform, or custom web application, we're committed to delivering outstanding success.
            </p>
            <a href="/contact" className="serve-button">Start Project</a>
          </div>
        </div>
      </div>
      <div className="serve-container1">
        <div className="col-md-6">
          <div className="serve-image2"></div>
        </div>
        <div className="col-md-6 serve1">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <g clip-path="url(#clip0_307_12168)">
              <path d="M51.5919 26.1224C51.5919 40.1888 40.1888 51.5918 26.1225 51.5918C12.0561 51.5918 0.653076 40.1888 0.653076 26.1224C0.653076 12.0561 12.0561 0.653023 26.1225 0.653023C40.1888 0.653023 51.5919 12.0561 51.5919 26.1224Z" stroke="#D100C9" stroke-width="2" />
              <path d="M63.3469 32C63.3469 46.0663 51.9439 57.4694 37.8776 57.4694C23.8112 57.4694 12.4082 46.0663 12.4082 32C12.4082 17.9336 23.8112 6.53059 37.8776 6.53059C51.9439 6.53059 63.3469 17.9336 63.3469 32Z" stroke="#D100C9" stroke-width="2" />
              <path d="M51.5919 37.8775C51.5919 51.9439 40.1888 63.3469 26.1225 63.3469C12.0561 63.3469 0.653076 51.9439 0.653076 37.8775C0.653076 23.8112 12.0561 12.4081 26.1225 12.4081C40.1888 12.4081 51.5919 23.8112 51.5919 37.8775Z" stroke="#D100C9" stroke-width="2" />
            </g>
            <defs>
              <clipPath id="clip0_307_12168">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h4 className="serve-title">UI/UX & Design</h4>
          <p className="serve-description">
            Our complete web design services will bring your ideas to life and provide you with a sleek, high-performing product that elevates your business. Our comprehensive UI/UX design services breathe life into your ideas and deliver a sleek, high-performing product that takes your business to new heights. We understand that an exceptional user interface and user experience are pivotal to a successful digital presence. Our team of seasoned UI/UX designers is dedicated to creating visually captivating, intuitive, and seamless user experiences.
          </p>
          <a href="/contact" className="serve-button">Start Project</a>
        </div>
      </div>
      <div className="serve-container">

        <div className="col-md-6">
          <div className="serve-image3"></div>
        </div>
        <div className="col-md-6 serve">


          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <g clip-path="url(#clip0_307_12174)">
              <path d="M47.6735 63.3469H16.3265C7.67033 63.3469 0.653076 56.3297 0.653076 47.6735V16.3265C0.653076 7.6703 7.67033 0.653046 16.3265 0.653046H47.6735C56.3297 0.653046 63.347 7.6703 63.347 16.3265V47.6735C63.347 56.3297 56.3297 63.3469 47.6735 63.3469Z" stroke="#D100C9" stroke-width="2" />
              <path d="M47.6735 63.3469H16.3265C7.67033 63.3469 0.653076 56.3297 0.653076 47.6735V16.3265C0.653076 7.6703 7.67033 0.653046 16.3265 0.653046H47.6735C56.3297 0.653046 63.347 7.6703 63.347 16.3265V47.6735C63.347 56.3297 56.3297 63.3469 47.6735 63.3469Z" stroke="#D100C9" stroke-width="2" />
              <path d="M45.0612 55.5103H18.9388C13.168 55.5103 8.48981 50.8321 8.48981 45.0613V18.9388C8.48981 13.168 13.168 8.48985 18.9388 8.48985H45.0612C50.832 8.48985 55.5102 13.168 55.5102 18.9388V45.0613C55.5102 50.8321 50.832 55.5103 45.0612 55.5103Z" stroke="#D100C9" stroke-width="2" />
              <path d="M42.449 47.6735H21.551C18.6656 47.6735 16.3265 45.3344 16.3265 42.449V21.551C16.3265 18.6656 18.6656 16.3265 21.551 16.3265H42.449C45.3344 16.3265 47.6735 18.6656 47.6735 21.551V42.449C47.6735 45.3344 45.3344 47.6735 42.449 47.6735Z" stroke="#D100C9" stroke-width="2" />
            </g>
            <defs>
              <clipPath id="clip0_307_12174">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <h4 className="serve-title">Mobile App Development</h4>
          <p className="serve-description">
            Our extensive mobile development experience allows us to create custom native and cross-platform iOS and Android mobile solutions for our clients. Our extensive experience in mobile app development empowers us to craft customized, cutting-edge solutions for both native and cross-platform iOS and Android mobile applications. In the digital age, mobile apps have become indispensable for businesses aiming to reach their audience effectively. We recognize the significance of mobile platforms and leverage our expertise to build apps that captivate, engage, and serve your unique business needs.
          </p>
          <a href="/contact" className="serve-button">Start Project</a>
        </div>
      </div>
      <div className="serve-container">

        <div className="col-md-6">
          <div className="serve-image4"></div>
        </div>
        <div className="col-md-6 serve1">


          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <g clip-path="url(#clip0_307_12179)">
              <path d="M37.2245 63.3469H11.1021C5.33124 63.3469 0.653076 58.6688 0.653076 52.8979V26.7755C0.653076 21.0047 5.33124 16.3265 11.1021 16.3265H37.2245C42.9953 16.3265 47.6735 21.0047 47.6735 26.7755V52.8979C47.6735 58.6688 42.9953 63.3469 37.2245 63.3469Z" stroke="#D100C9" stroke-width="2" />
              <path d="M52.898 47.6735H26.7755C21.0047 47.6735 16.3265 42.9953 16.3265 37.2245V11.102C16.3265 5.33122 21.0047 0.653053 26.7755 0.653053H52.898C58.6688 0.653053 63.3469 5.33122 63.3469 11.102V37.2245C63.3469 42.9953 58.6688 47.6735 52.898 47.6735Z" stroke="#D100C9" stroke-width="2" />
              <path d="M45.0612 55.5103H18.9387C13.1679 55.5103 8.48975 50.8321 8.48975 45.0613V18.9388C8.48975 13.168 13.1679 8.48985 18.9387 8.48985H45.0612C50.832 8.48985 55.5102 13.168 55.5102 18.9388V45.0613C55.5102 50.8321 50.832 55.5103 45.0612 55.5103Z" stroke="#D100C9" stroke-width="2" />
            </g>
            <defs>
              <clipPath id="clip0_307_12179">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h4 className="serve-title">AI Integrations & Big Data</h4>
          <p className="serve-description">
            We use market research to identify the best AI integrations that drive strategic growth and meet your business goals and deliver on your core mandates. We harness the power of Artificial Intelligence (AI) to unlock your business's full potential. In today's fast-paced digital landscape, AI technologies are a driving force behind innovation and growth. We are dedicated to utilizing AI in a way that aligns with your strategic vision and business objectives. Our approach begins with rigorous market research, allowing us to identify the most effective AI integrations that can revolutionize your operations, reduce costs, and enhance your customer experiences.
          </p>
          <a href="/contact" className="serve-button">Start Project</a>
        </div>
      </div>

      <div className="serve-container">

        <div className="col-md-6">
          <div className="serve-image5"></div>
        </div>
        <div className="col-md-6 serve">


          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">

            <circle cx="32" cy="32" r="28" stroke="#D100C9" stroke-width="2" fill="none" />


            <circle cx="32" cy="32" r="20" stroke="#D100C9" stroke-width="2" fill="none" />


            <circle cx="32" cy="32" r="14" stroke="#D100C9" stroke-width="2" fill="none" />


            <circle cx="32" cy="32" r="8" stroke="#D100C9" stroke-width="2" fill="none" />
          </svg>
          <h4 className="serve-title">Penetration Testing & Security</h4>
          <p className="serve-description">
            Our dedicated team of experts excels in identifying vulnerabilities and fortifying your systems, applications, and networks against potential security breaches. In an increasingly digital world, ensuring the security of your digital assets and sensitive information is paramount. Penetration testing, often referred to as ethical hacking, is a proactive and systematic approach to assessing the security of computer systems, networks, and applications. It involves simulating cyberattacks on your organization's infrastructure to identify vulnerabilities and weaknesses before malicious hackers can exploit them.
          </p>
          <a href="/contact" className="serve-button">Start Project</a>
        </div>
      </div>


    </section>
  );
}


export default WebSection;
