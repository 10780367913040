import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Navbar from './components/Navbar';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Package from "./pages/Package";
import Webdev from './components/links/Webdev';
import Footer from './components/Footer';
import Ui from './components/links/Ui';
import Mobile from './components/links/Mobile';
import Ai from './components/links/Ai';
import Testing from './components/links/Testing';
import Privacy from './components/legal/Privacy';
import Terms from './components/legal/Terms';
import Impressum from './components/legal/Impressum';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/packages" element={<Package />} />
          <Route path="/webdev" element={<Webdev />} />
          <Route path="/ui" element={<Ui />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
