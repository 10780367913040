import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import WebSection from '../components/services/WebSection';
import WebService from '../components/services/WebService';



function App() {
  return (
    <div className="App">
      <WebService />
      <WebSection />
      
    </div>
  );
}

export default App;
