import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="web-section">
      <div className="bag">
        <div className="ctent">
          <h1 className="display-4">Contact Us </h1>

        </div>
      </div>
    </div>
  );
}

export default Contact;
