import React from 'react';


function Ui() {
  return (
    <section className="serve-section">
      <div className="row">
        <div className="col-md-12">
          <section className="serve-section">
            <div className="offer-header">
              <div className="brand-header">
                <p className="rectangle-icon"></p>
                Mobile App Development
              </div>


            </div>
            <div className="se-content">
              <h3>Elevate Your Business with Sudocode's Mobile App Development</h3>

              <p>



                In today's fast-paced, mobile-first world, having a strong mobile presence is not just an option; it's a necessity. Sudocode is your trusted partner for crafting innovative, user-friendly, and high-performance mobile applications that will propel your business to new heights.



                At Sudocode, we believe that one size does not fit all. Our mobile app development services are tailored to your unique business needs. Whether you require a native iOS, Android, or cross-platform app, we have the expertise to design, develop, and deliver solutions that perfectly match your vision and objectives.



                Our mobile app development process starts with a deep understanding of your target audience. We design mobile apps that are not just visually appealing but also intuitive and user-centric. User experience is at the core of our design philosophy, ensuring that your app captivates and retains users.



                We understand that a mobile app should be fast and responsive. Sudocode engineers mobile apps with optimal performance, regardless of the complexity. Your users will enjoy a seamless experience, enhancing user engagement and satisfaction.



                Sudocode's mobile apps are designed for scalability and future growth. As your business expands, your app will be ready to accommodate new features and increasing user loads. We build apps that stand the test of time.



                Our mobile apps are not isolated entities. We seamlessly integrate them with your existing systems, databases, and third-party services. This ensures that your mobile app is a coherent part of your digital ecosystem.
              </p>

            </div>
          </section>
        </div>
      </div>

      <div className="serve-container">

        <div className="col-md-6">
          <div className="serve-image3"></div>
        </div>
        <div className="col-md-6 serve">


          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <g clip-path="url(#clip0_307_12174)">
              <path d="M47.6735 63.3469H16.3265C7.67033 63.3469 0.653076 56.3297 0.653076 47.6735V16.3265C0.653076 7.6703 7.67033 0.653046 16.3265 0.653046H47.6735C56.3297 0.653046 63.347 7.6703 63.347 16.3265V47.6735C63.347 56.3297 56.3297 63.3469 47.6735 63.3469Z" stroke="#D100C9" stroke-width="2" />
              <path d="M47.6735 63.3469H16.3265C7.67033 63.3469 0.653076 56.3297 0.653076 47.6735V16.3265C0.653076 7.6703 7.67033 0.653046 16.3265 0.653046H47.6735C56.3297 0.653046 63.347 7.6703 63.347 16.3265V47.6735C63.347 56.3297 56.3297 63.3469 47.6735 63.3469Z" stroke="#D100C9" stroke-width="2" />
              <path d="M45.0612 55.5103H18.9388C13.168 55.5103 8.48981 50.8321 8.48981 45.0613V18.9388C8.48981 13.168 13.168 8.48985 18.9388 8.48985H45.0612C50.832 8.48985 55.5102 13.168 55.5102 18.9388V45.0613C55.5102 50.8321 50.832 55.5103 45.0612 55.5103Z" stroke="#D100C9" stroke-width="2" />
              <path d="M42.449 47.6735H21.551C18.6656 47.6735 16.3265 45.3344 16.3265 42.449V21.551C16.3265 18.6656 18.6656 16.3265 21.551 16.3265H42.449C45.3344 16.3265 47.6735 18.6656 47.6735 21.551V42.449C47.6735 45.3344 45.3344 47.6735 42.449 47.6735Z" stroke="#D100C9" stroke-width="2" />
            </g>
            <defs>
              <clipPath id="clip0_307_12174">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <h4 className="serve-title">Mobile App Development</h4>
          <p className="serve-description">
            Our extensive mobile development experience allows us to create custom native and cross-platform iOS and Android mobile solutions for our clients. Our extensive experience in mobile app development empowers us to craft customized, cutting-edge solutions for both native and cross-platform iOS and Android mobile applications. In the digital age, mobile apps have become indispensable for businesses aiming to reach their audience effectively. We recognize the significance of mobile platforms and leverage our expertise to build apps that captivate, engage, and serve your unique business needs.
          </p>
          <a href="/contact" className="serve-button">Start Project</a>
        </div>
      </div>

    </section>
  );
}


export default Ui;
